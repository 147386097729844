import * as customer from '@/api/customer/customer'
import * as student from '@/api/customer/student'
import * as session from '@/api/customer/session'
import * as cart from '@/api/customer/cart'
import * as formAction from '@/api/customer/formAction'
import * as log from '@/api/customer/log'
const customerApi = {
  customer,
  student,
  session,
  cart,
  formAction,
  log,
}

export { customerApi };