import { computed } from 'vue';
import gql from 'graphql-tag';
import { useI18n } from 'vue-i18n';
import { TLiqpayConfig, TSiteparlorpageLinks } from '@/types/types';

export type TConfigurationsData = {
  core_siteparlorpage_by_pk: TSiteparlorpageLinks
  liqpayconfig:  TLiqpayConfig
}

export default () => {
  const { locale } = useI18n();

  const SESSIONS_PHOTOTRANSFER = computed(() => {
    return gql`
      query SESSIONS_PHOTOTRANSFER($where: apps_sessions_phototransfersession_bool_exp) {
        transfers: apps_sessions_phototransfersession(where: $where) {
          id
          image
          session_id
        }
      }
    `;
  })

  const AGREEMENT_INCREASED_RISK = computed(() => {
    return gql`
      query AGREEMENT_INCREASED_RISK($session_type_id: Int) {
        increasedrisk: customers_sessiontypesincreasedriskagreement(where: {apps_sessions_sessiontype: {id: {_eq: $session_type_id}}}) {
          text: text_${locale.value}
          text_default: text
        }
      }
    `;
  });

  const STUDENT_AGREEMENT_BY_SLUG = computed(() => {
    return gql`
      query STUDENT_AGREEMENT_BY_SLUG($slug: String) {
        agreements: agreement_studentagreement(where: { slug: { _eq: $slug } }) {
          fields: agreement_studentagreementfields(order_by: {id: asc}) {
            id
            is_required
            text
            title
            type
            answer_option
          }
          id
          title
          slug
        }
      }
    `;
  });

  const PARLOR_ADVANTAGES = computed(() => {
    return gql`
        query Advantages {
            advantages: core_advantage(order_by: {id: asc}) {
                id
                image
                title: title_${locale.value}
                description: description_${locale.value}
            }
        }
    `;
  });

  const PARLORS = computed(() => {
    return gql`
        query Parlors($where: core_parlor_bool_exp = {}) {
            parlors: core_parlor(
              where: $where
            ) {
                id
                name: name_${locale.value}
                name_default: name
                maps: google_maps_url
                is_grant
            }
        }
    `;
  });

  const CUSTOMER_PROBLEM_SITUATIONS = computed(() => {
    return gql`
      query CUSTOMER_PROBLEM_SITUATIONS($customerId: Int) {
        problemsituations: apps_sessions_problemsituation(
          where: { apps_sessions_session: { customer_id: { _eq: $customerId } } }
        ) {
          id
          status
        }
      }
    `;
  });

  const CONFIGURATIONS = computed(() => {
    return gql`
      query SITE_PARLOR_PAGE {
        core_siteparlorpage_by_pk(id: 1) {
          about_us
          ai_page_link
          complaints
          confidential_link
          discount_page
          dockuments_page_link
          develop_sketch_link
          fonts_page_link
          loyalty_page
          order_sketch_page
          public_offer_link
          page_FAQ
          quality_certificate
          try_sketch_page
          news_link
          shop_link
        }
        liqpayconfig: liqpay_liqpayconfig_by_pk(id: 1) {
          currency
        }
      }
    `;
  });

  const SESSIONS_TYPES = computed(() => {
    return gql`
        query SessionsType {
            session_types: apps_sessions_sessiontype(where: {is_active: {_eq: true}}) {
                id
                name: name_${locale.value}
                name_default: name
                price_per_hour
                locations: apps_sessions_sessiontypelocations {
                    id
                    name: name_${locale.value}
                    name_default: name
                }
            }
        }
    `;
  });

  const PARLORS_BY_NAME_INFO = computed(() => {
    return gql`
        query ParlorsByNameInfo ($parlor: String) {
            parlors: core_parlor(where: {name: {_eq: $parlor}}) {
                id
                photos: core_parlorphotos(limit: 15, where: {is_moderation: {_eq: true}}) {
                    photo
                }
                phone_number
                google_maps_url
                instagram_url
                facebook_url
                city: core_city {
                    name: name_${locale.value}
                    name_default: name
                }
                customers_count: customers_customers_aggregate(where: {confirmed: {_eq: true}}) {
                    aggregate {
                        count
                    }
                }
            }
        }
    `;
  });

  const CANDIDATES_BY_PHONE = computed(() => {
    return gql`
      query CANDIDATES_BY_PHONE($phone: String) {
        candidates: candidates_employeecandidate(where: { phone: { _eq: $phone } }) {
          id
        }
      }
    `;
  });

  const CLIENT_BY_PHONE = computed(() => {
    return gql`
      query CLIENT_BY_PHONE($phone: String, $email: String) {
        customers: customers_customer(
          where: { phone_number: { _eq: $phone }, email: { _ilike: $email } }
        ) {
          id
          full_name
          is_temporary_password
        }
        customers_has_password: customers_customer(
          where: {
            phone_number: { _eq: $phone }
            email: { _ilike: $email }
            password: { _is_null: false }
          }
        ) {
          id
          full_name
        }
      }
    `;
  });

  const SITEFORM_BY_PHONE = computed(() => {
    return gql`
      query SITEFORM_BY_PHONE($phone: String!) {
        customers: customers_siteform(where: { phone_number: { _eq: $phone } }) {
          id
        }
      }
    `;
  });

  const SESSIONS_BY_CLIENT_PAYMENT = computed(() => {
    return gql`
      query SESSIONS_BY_CLIENT_PAYMENT($customer_id: Int) {
        sessions: apps_sessions_session(
          where: {
            customer_id: { _eq: $customer_id }
            apps_sessions_sessionstatus: { status: { _eq: "600" } }
          }
          order_by: { date: desc }
        ) {
          id
          parlor: core_parlor {
            name
          }
          date
          time
          href_payment
        }
      }
    `;
  });

  const LIQPAY_CONFIGURATION = computed(() => {
    return gql`
      query LIQPAY_CONFIGURATION {
        config: liqpay_liqpayconfig_by_pk(id: 1) {
          currency
        }
      }
    `;
  });

  const TRAINING_PACKAGES = computed(() => {
    return gql`
      query TRAINING_PACKAGES {
        packages: core_trainingpagepackage(order_by: { id: asc }) {
          id
          header
          type
          subtitle
          card_color
          card_heading_text_color
          count_badge_text
          price
          old_price
          advantages: core_trainingadvantages {
            id
            name
          }
          lessons: core_trainingpagelessons {
            id
            name
            type
          }
        }
      }
    `;
  });

  const CONFIGURATION_TRAINING_URLS = computed(() => {
    return gql`
      query CONFIGURATION_TRAINING_URLS {
        configuration: configuration_by_pk(id: 1) {
          training_facebook_url
          training_instagram_url
          training_tiktok_url
          training_youtube_url
        }
      }
    `;
  });

  const CONFIDENTIAL_POLITIC_LINK = computed(() => {
    return gql`
      query CONFIDENTIAL_POLITIC_LINK {
        core: core_siteparlorpage_by_pk(id: 1) {
          link: confidential_link
        }
      }
    `;
  });

  const CLIENT_BY_SESSION_ID = computed(() => {
    return gql`
      query CLIENT_BY_SESSION_ID($id: Int!) {
        session: apps_sessions_session_by_pk(id: $id) {
          customer: customers_customer {
            id
            full_name
            birth_date
            email
            find_out_id
          }
        }
      }
    `;
  });

  const CUSTOMER_BY_ID = computed(() => {
    return gql`
      query CUSTOMER_BY_ID($id: Int!) {
        customer: customers_customer_by_pk(id: $id) {
          balance_buy
          phone_number
          email
          email_confirmed
          confirmed
          parlor_id
          language
        }
      }
    `;
  });

  const AGREEMENT_SESSION_TYPES = computed(() => {
    return gql`
        query AGREEMENT_SESSION_TYPES {
            sessiontype: apps_sessions_sessiontype(where: {use_in_agreement: {_eq: true}}) {
                icon: agreement_icon
                id
                name: name_${locale.value}
                name_default: name
            }
        }
    `;
  });

  const MASTER_PAGE_MASTER_INFO = computed(() => {
    return gql`
        query MASTER_PAGE_MASTER_INFO($id: Int!) {
            user: accounts_user_by_pk(id: $id) {
                is_active
                first_name
                last_name
                styles: accounts_user_styles(where: {accounts_style: {}}) {
                    id
                    style: accounts_style {
                        id
                        name: name_${locale.value}
                        name_default: name
                    }
                }
                profile: accounts_profile {
                    id
                    avatar
                    rank
                    about_you
                    career_start
                    parlor: core_parlor {
                        id
                        name: name_${locale.value}
                        name_default: name
                    }
                }
                sessions: appsSessionsSessionsByMasterId_aggregate(where: {apps_sessions_sessionstatus: {status: {_eq: "400"}}}) {
                  aggregate {
                    count
                  }
                }
                session_types: accounts_user_session_types(where: {apps_sessions_sessiontype:{}}) {
                    id
                    session_type: apps_sessions_sessiontype {
                        name: name_${locale.value}
                        name_default: name
                    }
                }
                photos: accountsMasterphotosByUserId(where: {is_moderation: {_eq: true}}) {
                    id
                    image
                }
                awards: accountsAwardsdiplomasByUserId(where: {is_moderation: {_eq: true}, show_on_site: {_eq: true}}) {
                    id
                    image
                }
            }
        }
    `;
  });

  const STYLES = computed(() => {
    return gql`
        query STYLES {
          styles: accounts_style {
            id
            name: name_${locale.value}
            name_default: name
          }
        }
    `;
  });

  const SESSION_PRICES = computed(() => {
    return gql`
        query SESSION_PRICES {
            prices: apps_sessions_sessiontype(where: {is_active: {_eq: true}, show: {_eq: true}}) {
                id
                name: name_${locale.value}
                name_default: name
                price: price_per_hour
                sessions: apps_sessions_sessions(limit: 5, where: {apps_sessions_questionnaire: {apps_sessions_questionnairephotos: {image: {_is_null: false}}, social_used: {_eq: true}, pretty: {_eq: true}}}) {
                    id
                    questionnaire: apps_sessions_questionnaire {
                        id
                        questionnairephotos: apps_sessions_questionnairephotos(limit: 5) {
                            id
                            image
                        }
                    }
                }
                locations: apps_sessions_sessiontypelocations {
                    id
                    sessionimageone
                    sessionimagetwo
                    name: name_${locale.value}
                    name_default: name
                    sessions: apps_sessions_sessions(limit: 5, where: {apps_sessions_questionnaire: {apps_sessions_questionnairephotos: {image: {_is_null: false}}, social_used: {_eq: true}, pretty: {_eq: true}}}) {
                        id
                        questionnaire: apps_sessions_questionnaire {
                            id
                            questionnairephotos: apps_sessions_questionnairephotos(limit: 5) {
                                id
                                image
                            }
                        }
                    }
                }
            }
        }
    `;
  });

  const STORE_ITEM = computed(() => {
    return gql`
      query STORE_ITEM($id: Int!) {
        item: store_item_by_pk(id: $id) {
          id
          name: name_${locale.value}
          name_default: name
          image
          sell_price
          shop_price
          full_description
          attribute: store_attribute {
            name
          }
          category: store_category {
            id
            name: name_${locale.value}
          name_default: name
          }
          parlorstores: store_parlorstores {
            value
            parlor_id
            parlor: core_parlor {
              id
              name: name_${locale.value}
              name_default: name
            }
          }
        }
      }
    `;
  });

  const STORE_ITEMS = computed(() => {
    return gql`
      query STORE_ITEMS(
        $limit: Int = 12
        $offset: Int = 0
        $name: String
        $category: Int
        $parlor: Int
        $minimum_count: numeric
        $tags: [Int]
      ) {
        items: store_item(
          limit: $limit
          order_by: { id: desc }
          offset: $offset
          where: {
            _or: [{store_item_tags: {store_tagitem: {}}}, {_not: {store_item_tags: {}}}]
            name: { _like: $name }
            store_category: { id: { _eq: $category } }
            store_parlorstores: { parlor_id: { _eq: $parlor }, value: { _gte: $minimum_count } }
            store_item_tags: {tagitem_id: { _in: $tags }}
          }
        ) {
          id
          name: name_${locale.value}
          name_default: name
          image
          sell_price
          shop_price
          parlorstores: store_parlorstores {
            id
            value
            parlor_id
            parlor: core_parlor {
              id
              name: name_${locale.value}
              name_default: name
            }
          }
          attribute: store_attribute {
            name
          }
          item_tags: store_item_tags {
            tagitem: store_tagitem {
              id
              name: name_${locale.value}
              name_default: name
            }
          }
          category: store_category {
            id
            name: name_${locale.value}
            name_default: name
          }
        }
        total: store_item_aggregate(
          where: {
            name: { _like: $name }
            store_category: { id: { _eq: $category } }
            store_parlorstores: { parlor_id: { _eq: $parlor }, value: { _gt: "0" } }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `;
  });

  const CERTIFICATES_CERTIFICATEKINDTYPE = computed(() => {
    return gql`
      query CERTIFICATES_CERTIFICATEKINDTYPE {
        kindtype: certificates_certificatekindtype {
          id
          name
          description: customer_description_${locale.value}
          description_default: description
          layout
          app_photo
          fix_price
          cash_back
        }
      }
    `;
  })

  const PARLOR_WALLETS = computed(() => {
    return gql`
      query PARLOR_WALLETS($parlor_id: Int!) {
        wallets: transactions_parlorwallet(where: { parlor_id: { _eq: $parlor_id } }) {
          name
          status
        }
      }
    `;
  })

  const CARTS_BY_CUSTOMER = computed(() => {
    return gql`
      query CARTS_BY_CUSTOMER($customer_id: Int!) {
        customercart: customers_customercart(
          where: { customer_id: { _eq: $customer_id }, status: { _eq: "new_order" } }
        ) {
          id
          quantity
          item: store_item {
            id
            name
            image
            sell_price
            shop_price
            attribute: store_attribute {
              name
            }
            category: store_category {
              id
              name
            }
          }
        }
      }
    `;
  });

  const STORE_ITEMS_CATEGORY = computed(() => {
    return gql`
      query STORE_ITEMS_CATEGORY {
        category: store_category {
          id
          name
        }
      }
    `;
  });


  const STORE_TAGITEMS = computed(() =>
    gql`
      query STORE_TAGITEMS {
        store_tagitem {
            id
            name: name_${locale.value}
            name_default: name
          }
        }
    `
  )

  const LANDING_PARLOR_BY_ID = computed(() => {
    return gql`
        query LANDING_PARLOR_BY_ID($parlorId: Int!) {
            masters: accounts_user(
                where: {
                    is_active: { _eq: true }
                    accounts_profile: {
                        _or: [
                            {core_parlor: {id: {_eq: $parlorId}}}
                            {accounts_profile_parlors: {id: {_eq: $parlorId}}}
                        ]
                    }
                    role: { _in: ["master", "administrator"] }
                }
            ) {
                id
                first_name
                last_name
                profile: accounts_profile {
                    id
                    avatar
                    rank
                    parlor: core_parlor {
                        id
                        name: name_${locale.value}
                        name_default: name
                    }
                }
                styles: accounts_user_styles {
                    id
                    style: accounts_style {
                        id
                        name: name_${locale.value}
                        name_default: name
                    }
                }
                session_types: accounts_user_session_types(
                    where: {
                        apps_sessions_sessiontype: {
                            is_active: {
                                _eq: true
                            }
                            show_on_site: {
                                _eq: true
                            }
                        }
                    }
                ) {
                    session_type: apps_sessions_sessiontype {
                        id
                        name: name_${locale.value}
                        name_default: name
                        upload_sketch
                        increased_risk
                        locations: apps_sessions_sessiontypelocations {
                            id
                            name: name_${locale.value}
                            name_default: name
                            increased_risk
                        }
                        styles: apps_sessions_sessiontypestyles {
                            style: accounts_style {
                                id
                                name: name_${locale.value}
                                name_default: name
                            }
                        }
                        sizes: apps_sessions_sessiontypesizes {
                            id
                            size
                        }
                    }
                }
                sessions_aggregate: apps_sessions_sessions_aggregate(where: {apps_sessions_sessionstatus: {status: {_eq: "400"}}}) {
                    aggregate {
                        count
                    }
                }
            }
            top_works: accounts_masterphoto(
              where: {
                is_show_on_site: {_eq: true}
                accounts_user: {accounts_profile: {parlor_id: {_eq: $parlorId}}}
              }, limit: 10, order_by: {id: desc}
            ) {
                id
                image
            }
            parlor: core_parlor_by_pk(id: $parlorId) {
                id
                name: name_${locale.value}
                name_default: name
                maps: google_maps_url
                customers_aggregate: customers_customers_aggregate {
                    aggregate {
                        count
                    }
                }
            }
        }
    `;
  });

  const MASTERS_BY_PARLOR = computed(() => {
    return gql`
      query MASTERS_BY_PARLOR($id: Int!, $sessionType: Int) {
          masters: accounts_user(
              where: {
                  is_active: { _eq: true }
                  accounts_profile: {
                      _or: [
                          {core_parlor: {id: {_eq: $id}}}
                          {accounts_profile_parlors: {id: {_eq: $id}}}
                      ]
                  }
                  role: { _in: ["master", "administrator"] }
                  accounts_user_session_types: {sessiontype_id: {_eq: $sessionType}}
              }
          ) {
              id
              first_name
              last_name
              profile: accounts_profile {
                  id
                  avatar
                  rank
                  parlor: core_parlor {
                      id
                      name: name_${locale.value}
                      name_default: name
                  }
              }
          }
      }
    `;
  });

  const APPROXIMATE_PRICE = computed(() => {
    return gql`
      query APPROXIMATE_PRICE($parlor: Int, $type: Int, $location: Int) {
        parlorprices: price_parlorprice(
          where: {
            parlor_id: { _eq: $parlor }
            price_priceservice: { location_id: { _eq: $location }, session_type_id: { _eq: $type } }
          }
        ) {
          parlor_id
          approved
          pricecategory: price_pricecategory {
            name
            priceitems: price_priceitems(
              where: {
                price_priceservice: {
                  location_id: { _eq: $location }
                  session_type_id: { _eq: $type }
                }
              }
            ) {
              id
              price
              priceservice: price_priceservice {
                sessiontype: apps_sessions_sessiontype {
                  id
                  name
                }
                sessiontypelocation: apps_sessions_sessiontypelocation {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `;
  });

  const MASTER_PHOTO_PAGINATED = computed(() => {
    return gql`
      query MASTER_PHOTO_PAGINATED($offset: Int = 0, $limit: Int = 5) {
        masterphoto: accounts_masterphoto(
          where: { is_show_on_site: { _eq: true }, is_moderation: { _eq: true } }
          limit: $limit
          offset: $offset
          order_by: { id: desc }
        ) {
          id
          image
        }
        total: accounts_masterphoto_aggregate(
          where: { is_show_on_site: { _eq: true }, is_moderation: { _eq: true } }
          order_by: { id: desc }
        ) {
          aggregate {
            count
          }
        }
      }
    `;
  });

  const CUSTOMERS_FINDOUT = computed(() => {
    return gql`
        query CUSTOMERS_FINDOUT {
            findouts: customers_findout(where: {show_client: {_eq: true}}) {
                name: name_${locale.value}
                name_default: name
                id
            }
        }
    `;
  });

  const SESSION_HEALING = computed(() => {
    return gql`
        query SESSION_HEALING($id: Int = 1) {
            session: apps_sessions_session_by_pk(id: $id) {
                parlor_id
                type: apps_sessions_sessiontype {
                    id
                    name: name_${locale.value}
                    name_default: name
                    healing_text: healing_text_${locale.value}
                    healing_text_default: healing_text
                }
                location: apps_sessions_sessiontypelocation {
                    id
                    name: name_${locale.value}
                    name_default: name
                    healing_text: healing_text_${locale.value}
                    healing_text_default: healing_text
                }
                recommendation: apps_sessions_healingrecommendationmaster {
                  id
                  name
                  text: text_${locale.value}
                  text_default: text
                }
                consumables: apps_sessions_consumables {
                  id
                  value
                  item: store_item {
                    id
                    name: name_${locale.value}
                    name_default: name
                    image
                  }
                }
            }
        }
    `;
  });

  const SESSION_RECOMMENDED_PRODUCTS = computed(() => {
    return gql`
      query SESSION_RECOMMENDED_PRODUCTS($session_type: Int, $parlor: Int) {
        items: store_parlorstore(
          where: {
            parlor_id: { _eq: $parlor }
            value: { _gt: 0 }
            store_item: {store_item_tags: {store_tagitem: {apps_sessions_sessiontype_itemtags: {sessiontype_id: {_eq: $session_type}}}}}
          }
        ) {
          id
          item: store_item {
            id
            name
            image
            sell_price
            attribute: store_attribute {
              name
            }
            description
            store_amount
          }
        }
        souvenir_items: store_parlorstore(
          where: {
            parlor_id: { _eq: $parlor }
            value: { _gt: 0 }
            store_item: {store_item_tags: {store_tagitem: {name_ru: {_eq: "Сувенирная продукция"}}}}
          }
        ) {
          id
          item: store_item {
            id
            name
            image
            sell_price
            attribute: store_attribute {
              name
            }
            description
            store_amount
          }
        }
      }
    `;
  });

  const SESSION_BY_ID = computed(() => {
    return gql`
        query SESSION_BY_ID($id: Int!) {
            session: apps_sessions_session_by_pk(id: $id) {
                id
                date
                time
                is_mystery_shopper
                customer_rate
                href_payment
                sessiontype: apps_sessions_sessiontype {
                    id
                    increased_risk
                    name: name_${locale.value}
                    name_default: name
                }
                locations: apps_sessions_sessiontypelocation {
                    id
                    increased_risk
                }
                parlor: core_parlor {
                    id
                    name: name_${locale.value}
                    name_default: name
                }
                master: accountsUserByMasterId {
                    id
                    role
                    first_name
                    studentprofile: accounts_studentprofile {
                      id
                    }
                    profile: accounts_profile {
                      avatar
                      rank
                    }
                    sessions: appsSessionsSessionsByMasterId_aggregate(where: {apps_sessions_sessionstatus: {status: {_eq: "400"}}}) {
                      aggregate {
                        count
                      }
                    }
                }
                items: apps_sessions_consumables {
                  id
                  value
                  item_id
                  item: store_item {
                    id
                    name
                    sell_price
                    variant: store_attribute {
                      id
                      name
                    }
                  }
                }
                tips: customers_tips {
                  id
                  amount
                  is_active
                }
                questionnaire: apps_sessions_questionnaire {
                  id
                  price
                }
                parlor: core_parlor {
                    id
                    google_maps_url
                    name: name_${locale.value}
                    name_default: name
                }
            }
        }
    `;
  });

  const SESSION_APPROXIMATE_PRICE = computed(() => {
    return gql`
      query SESSION_APPROXIMATE_PRICE($id: Int!) {
        session: apps_sessions_session_by_pk(id: $id) {
          id
          approximate_price
        }
      }
    `;
  });

  const AI_SKETCHES = computed(() => {
    return gql`
      query AI_SKETCHES($customerId: Int) {
        gallery: ai_gallery_image(where: { created_by_customer_id: { _eq: $customerId } }) {
          id
          image
          prompt
          created_at
        }
      }
    `;
  });

  const AGREEMENT_TEXT_ITEMS = computed(() => {
    return gql`
        query AGREEMENT_TEXT_ITEMS($type: String, $session_type_id: Int) {
            items: customers_textitemagreement(where: {type: {_eq: $type}, session_type_id: {_eq: $session_type_id}}, order_by: {id: asc}) {
                id
                text: text_${locale.value}
                text_default: text
                heading: heading_${locale.value}
                heading_default: heading
                type
            }
        }
    `;
  });

  const AGREEMENT_CHECKBOX_ITEMS = computed(() => {
    return gql`
        query AGREEMENT_CHECKBOX_ITEMS {
            items: customers_checkboxitemagreement {
                id
                text: text_${locale.value}
                text_default: text
                type
            }
        }
    `;
  });
  const AGREEMENT_TEXT = computed(() => {
    return gql`
        query AGREEMENT_TEXT {
            agreement: customers_agreement {
                large_text: large_text_${locale.value}
                large_text_default: large_text
            }
        }
    `;
  });

  const CUSTOMER_SESSIONS = computed(() => {
    return gql`
      query CUSTOMER_SESSIONS($date: date, $status: smallint, $parlor: Int, $date_from: date, $date_to: date) {
        sessions: apps_sessions_session(
          where: {
            date: {_eq: $date, _gte: $date_from, _lte: $date_to},
            parlor_id: {_eq: $parlor}
            apps_sessions_sessionstatus: {status: {_eq: $status}}
          },
          order_by: {date: desc}
        ) {
          id
          date
          time
          agreement: customers_agreementanswer {
            id
          }
          sessiontype: apps_sessions_sessiontype {
            id
            name: name_${locale.value}
            name_default: name
          }
          parlor: core_parlor {
            id
            name: name_${locale.value}
            name_default: name
          }
          sessionstatus: apps_sessions_sessionstatus {
            status
          }
        }
      }
    `;
  });

  const SESSION_STATUS = computed(() => {
    return gql`
      query SESSION_STATUS($id: Int!) {
        session: apps_sessions_session_by_pk(id: $id) {
          id
          close: admin_close_session
          sessionstatus: apps_sessions_sessionstatus {
            status
          }
        }
      }
    `;
  });

  const SESSION_INCREASED_RISK = computed(() => {
    return gql`
      query SESSION_INCREASED_RISK($id: Int!) {
        session: apps_sessions_session_by_pk(id: $id) {
          sessiontype: apps_sessions_sessiontype {
            id
            increased_risk
          }
          locations: apps_sessions_sessiontypelocation {
            id
            increased_risk
          }
        }
      }
    `;
  });

  const SESSION_TYPE_LOCATIONS_BY_TYPE = computed(() => {
    return gql`
    query SESSION_TYPE_LOCATIONS_BY_TYPE($id: Int) {
      locations: apps_sessions_sessiontypelocation(where: {type_id: {_eq: $id}}) {
        id
        name: name_${locale.value}
        name_default: name
      }
    }
    `;
  });

  const SESSIONS_LIST = computed(() => {
    return gql`
      query SESSIONS(
        $where: apps_sessions_session_bool_exp = {}
        $offset: Int = 0
        $limit: Int = 10
        $order: [apps_sessions_session_order_by!] = {}
      ) {
        sessions: apps_sessions_session(
          where: $where
          limit: $limit
          offset: $offset
          order_by: $order
        ) {
          id
          date
          time
          isCredit: is_credit
          parlor: core_parlor {
            id
            name
          }
          master: accountsUserByMasterId {
            id
            first_name
            last_name
          }
          sessionType: apps_sessions_sessiontype {
            id
            name
          }
          status: apps_sessions_sessionstatus {
            status
          }
        }
        total: apps_sessions_session_aggregate(where: $where) {
          aggregate {
            count
          }
        }
      }
    `;
  });

  const STUDENT_RENT_TEXT = computed(() => {
    return gql`
      query STUDENT_RENT_TEXT {
        agreement: customers_agreement_by_pk(id: 1) {
          id
          student_text: student_text_${locale.value}
          student_text_default: student_text
        }
      }
    `;
  });

  const PARLOR_COUCHES = computed(() => {
    return gql`
      query PARLOR_COUCHES($where: core_parlorcouch_bool_exp) {
        couches: core_parlorcouch(
          where: $where
        ) {
          id
          parlor: parlor_id
          name
          photo
          for_rent
          days_for_rentals
          couch_price: core_couchprice {
            id
            name
            type
            price
          }
        }
      }
    `
  });

  const RENT_COUCH_CONFIGURATION = gql`
    query RENT_COUCH_CONFIGURATION {
      configuration: core_rentcouchconfiguration_by_pk(id: 1) {
        rent_conditions
        agreement_customer
      }
    }
  `

  const SESSION_OF_CUSTOMER = computed(() => {
    return gql`
      query SESSION_OF_CUSTOMER($id: Int!) {
        session: apps_sessions_session_by_pk(id: $id) {
          id
          date
          time
          parlor: core_parlor {
            id
            name
          }
          master: accountsUserByMasterId {
            id
            first_name
            last_name
          }
          sessionType: apps_sessions_sessiontype {
            id
            name
          }
          status: apps_sessions_sessionstatus {
            status
          }
          agreement: customers_agreementanswer {
            id
            pdf
          }
        }
    }`;
  });

  const STUDYING = computed(() => {
    return gql`
      query TYPES_OF_EDUCATION {
        categories: students_typeeducation {
          name
          id
          hours
          count_practik
          comment
          need_sketch
        }
      }
    `;
  });

  const TYPES_OF_EDUCATION_PRICES = computed(() => {
    return gql`
      query Prices($type_education: Int, $parlor: Int) {
        parlor: price_parlorprice(
          where: {
            parlor_id: { _eq: $parlor },
            price_priceservice: { studying: { _eq: true } }
          }
        ) {
          id
          price_pricecategory {
            id
            name
            price_priceitems(
              where: { price_priceservice: { type_education_id: { _eq: $type_education } } }
            ) {
              id
              price
              price_priceservice {
                id
                studying
                students_typeeducation {
                  name
                  id
                }
                type_education_id
              }
            }
          }
        }
      }
    `;
  });
  
  return {
    SESSIONS_PHOTOTRANSFER: SESSIONS_PHOTOTRANSFER.value,
    AGREEMENT_CHECKBOX_ITEMS: AGREEMENT_CHECKBOX_ITEMS.value,
    AGREEMENT_INCREASED_RISK: AGREEMENT_INCREASED_RISK.value,
    AGREEMENT_SESSION_TYPES: AGREEMENT_SESSION_TYPES.value,
    AGREEMENT_TEXT: AGREEMENT_TEXT.value,
    AGREEMENT_TEXT_ITEMS: AGREEMENT_TEXT_ITEMS.value,
    AI_SKETCHES: AI_SKETCHES.value,
    APPROXIMATE_PRICE: APPROXIMATE_PRICE.value,
    CARTS_BY_CUSTOMER: CARTS_BY_CUSTOMER.value,
    CANDIDATES_BY_PHONE: CANDIDATES_BY_PHONE.value,
    CLIENT_BY_PHONE: CLIENT_BY_PHONE.value,
    CLIENT_BY_SESSION_ID: CLIENT_BY_SESSION_ID.value,
    CONFIGURATION_TRAINING_URLS: CONFIGURATION_TRAINING_URLS.value,
    CONFIGURATIONS: CONFIGURATIONS.value,
    CONFIDENTIAL_POLITIC_LINK: CONFIDENTIAL_POLITIC_LINK.value,
    CUSTOMER_BY_ID: CUSTOMER_BY_ID.value,
    CUSTOMER_PROBLEM_SITUATIONS: CUSTOMER_PROBLEM_SITUATIONS.value,
    CUSTOMER_SESSIONS: CUSTOMER_SESSIONS.value,
    CUSTOMERS_FINDOUT: CUSTOMERS_FINDOUT.value,
    LANDING_PARLOR_BY_ID: LANDING_PARLOR_BY_ID.value,
    LIQPAY_CONFIGURATION: LIQPAY_CONFIGURATION.value,
    MASTERS_BY_PARLOR: MASTERS_BY_PARLOR.value,
    MASTER_PAGE_MASTER_INFO: MASTER_PAGE_MASTER_INFO.value,
    MASTER_PHOTO_PAGINATED: MASTER_PHOTO_PAGINATED.value,
    PARLOR_ADVANTAGES: PARLOR_ADVANTAGES.value,
    PARLOR_COUCHES: PARLOR_COUCHES.value,
    PARLORS: PARLORS.value,
    PARLORS_BY_NAME_INFO: PARLORS_BY_NAME_INFO.value,
    RENT_COUCH_CONFIGURATION: RENT_COUCH_CONFIGURATION,
    SESSIONS_BY_CLIENT_PAYMENT: SESSIONS_BY_CLIENT_PAYMENT.value,
    SESSIONS_LIST: SESSIONS_LIST.value,
    SESSIONS_TYPES: SESSIONS_TYPES.value,
    SESSION_APPROXIMATE_PRICE: SESSION_APPROXIMATE_PRICE.value,
    SESSION_BY_ID: SESSION_BY_ID.value,
    SESSION_HEALING: SESSION_HEALING.value,
    SESSION_INCREASED_RISK: SESSION_INCREASED_RISK.value,
    SESSION_OF_CUSTOMER: SESSION_OF_CUSTOMER.value,
    SESSION_PRICES: SESSION_PRICES.value,
    SESSION_RECOMMENDED_PRODUCTS: SESSION_RECOMMENDED_PRODUCTS.value,
    SESSION_STATUS: SESSION_STATUS.value,
    SESSION_TYPE_LOCATIONS_BY_TYPE: SESSION_TYPE_LOCATIONS_BY_TYPE.value,
    SITEFORM_BY_PHONE: SITEFORM_BY_PHONE.value,
    STORE_ITEM: STORE_ITEM.value,
    STORE_ITEMS: STORE_ITEMS.value,
    STORE_ITEMS_CATEGORY: STORE_ITEMS_CATEGORY.value,
    STUDENT_AGREEMENT_BY_SLUG: STUDENT_AGREEMENT_BY_SLUG.value,
    STUDENT_RENT_TEXT: STUDENT_RENT_TEXT.value,
    STYLES: STYLES.value,
    TRAINING_PACKAGES: TRAINING_PACKAGES.value,
    STORE_TAGITEMS: STORE_TAGITEMS.value,
    STUDYING: STUDYING.value,
    CERTIFICATES_CERTIFICATEKINDTYPE: CERTIFICATES_CERTIFICATEKINDTYPE.value,
    PARLOR_WALLETS: PARLOR_WALLETS.value,
    TYPES_OF_EDUCATION_PRICES: TYPES_OF_EDUCATION_PRICES.value,
  };
};
