export default {
  support: {
    problem_situation_link: 'Ich habe ein Problem nach der Sitzung',
    other_problem_link: 'Ich habe ein anderes Problem',
    problem_situation: {
      header: 'Problem nach der Sitzung',
      form: {
        session: 'Sitzung',
        created_by_comment: 'Beschreiben Sie das Problem',
      },
    },
    complain: {
      header: 'Beschwerden und Anregungen',
      form: {
        name: 'Name',
        phone_number: 'Telefonnummer',
        email: 'E-Mail',
        parlor: 'Salon',
        description: 'Beschreiben Sie das Problem',
        feedback: {
          overall_quality: 'Wie bewerten Sie die Gesamtqualität des Service in unserem Salon?',
          professional_skills: 'Waren Sie mit den fachlichen Fähigkeiten des Meisters zufrieden?',
          staff_politeness: 'Wie höflich und aufmerksam war das Salonpersonal?',
          waiting_time: 'Waren Sie mit der Wartezeit für Ihre Dienstleistung zufrieden?',
          staff_professionalism: 'Wie bewerten Sie die Professionalität unseres Personals?',
          salon_cleanliness: 'Wie bewerten Sie die Sauberkeit und den Komfort in unserem Salon?',
          service_value:
            'Glauben Sie, dass die Kosten für Dienstleistungen ihrer Qualität entsprechen?',
          recommend: 'Würden Sie unseren Salon Ihren Freunden und Bekannten empfehlen?',
        },
      },
    },
  },
  items_view: {
    header: 'Produkte',
    filter_form: {
      name_placeholder: 'Name',
      category_placeholder: 'Kategorie',
      parlor_placeholder: 'Salon',
      all_categories: 'Alle Kategorien',
      all_parlors: 'Alle Salons',
    },
    load_more_button: 'Mehr laden',
    not_found_message: 'Nichts gefunden',
  },
  item_view: {
    error_message: 'Fehler beim Laden der Daten',
    available_in_parlor: 'In deinem Salon verfügbar',
    not_available_in_parlor: 'In deinem Salon nicht verfügbar',
    similar_items_label: 'Ähnliche Produkte',
    log_in_to_buy: 'Zum Kauf anmelden',
  },
  item_simple_card: {
    studio_price_label: 'Studio-Preis',
    shop_price_label: 'Ladenpreis',
  },
  student_candidate: {
    form: {
      who_studing: 'Student oder Platzmiete',
      phone_number: 'Telefonnummer',
      first_name: 'Vorname',
      last_name: 'Nachname',
      social_link: 'Link zu einem sozialen Netzwerk',
      parlors: 'Studio',
      couch: 'Tätowierstuhl',
      find_out: 'Wie haben Sie von uns erfahren?',
      start_date: 'Gewünschtes Startdatum',
      types: 'Sitzungstypen',
      work_permit_document: 'Arbeitserlaubnisdokument',
      sanitary_book: 'Sanitärbuch',
      identity_proof: 'Identitätsnachweis',
      work_photos: 'Arbeitsfotos',
      end_date: 'Gewünschtes Enddatum',
      confirmations: 'Ich bestätige, dass',
      know_buy_material: 'Ich weiß, dass ich Materialien im Studio kaufen kann',
      customer_will_complete_agreement: 'Jeder meiner Kunden wird eine Vereinbarung unterzeichnen',
      can_show_documents: 'Ich kann bei Bedarf Originaldokumente im Studio vorlegen',
      has_country_permission: 'Ich habe eine Aufenthaltserlaubnis',
      has_san_book: 'Ich habe ein Sanitärbuch',
      has_passport: 'Ich habe einen Personalausweis (ID)',
      conditions: 'Ich habe die Mietbedingungen gelesen',
    },
  },
  user: {
    referral_links: {
      booking: 'Sitzungsbuchung',
      student_candidate: 'Mietplatz',
      store: 'Kundenladen',
      candidates_form: 'Mitarbeiter einladen',
    },
  },
  products: {
    want_add: 'Ich möchte die ausgewählten Pflegeprodukte zu den Kosten der Sitzung hinzufügen',
    no_warranty: '*ohne diese Option können wir Ihnen keine 100% erfolgreiche Heilung garantieren',
    button_add: 'Hinzufügen',
  },
  healing_questionary: {
    composable_table: {
      heading: 'Verbrauchte Materialien',
      name: 'Name',
      count: 'Menge',
    },
  },
  booking: {
    fast_booking: 'Schnelle Buchung',
    develop_sketch: 'Skizzenentwicklung',
    fast_booking_warning:
      'Die Buchung ist nicht endgültig, unser Manager wird sich mit Ihnen in Verbindung setzen, um die Zeit und das Datum der Sitzung zu bestätigen',
    phone_annotate:
      'Um unserem Vertriebsteam zu helfen, schneller zu reagieren, geben Sie bitte Ihre lokale Kontaktnummer an. Danke!',
    increased_risk: 'Achtung! Hochrisikositzung',
    increased_risk_extended:
      'Der ausgewählte Sitzungstyp und die Körperstelle haben ein erhöhtes Risiko',
    approximate_price: 'Ungefährer Preis',
    price_warning: 'Zusätzliche Artikel sind nicht enthalten',
    type: {
      info: 'Bequemes Formular zur Sitzungsanmeldung mit allen notwendigen Informationen',
      booking_button: 'SITZUNG BUCHEN',
      booking_button_promo: 'MIT 15% CASHBACK IN VEAN COINS',
      unsure: 'Noch unsicher bei der Auswahl? Wir helfen Ihnen!',
      consultant_help: 'Unsere Berater helfen Ihnen gerne weiter',
      consultation_button: 'BERATUNG',
    },
    color: {
      black: 'Schwarz',
      colored: 'Farbig',
    },
    controls: {
      next: 'WEITER',
      back: 'Zurück',
      no_select: 'Nicht ausgewählt',
    },
    place_service: {
      select_studio: 'Studio auswählen',
      service: 'Dienstleistung',
      size: 'Größe',
      location: 'Standort',
    },
    customer_info: {
      login_prompt: 'Bitte melden Sie sich an, um fortzufahren',
      login_button: 'Einloggen',
      black_white_colored: 'Schwarzweiß oder farbig?',
      select_style: 'Stil auswählen',
      tell_more:
        'Erzählen Sie uns mehr über Ihre Tattoo-Vorlieben und teilen Sie Beispiele (Referenzen)',
      login_promo: 'Beim Einloggen 10% Cashback',
      wish: 'Wunsch',
    },
    date_time: {
      choose_time: 'Besuchszeit wählen',
    },
    master_card: {
      master_unavailable: 'Der Meister ist zu diesem Zeitpunkt nicht verfügbar',
      master_guest_warning: 'ACHTUNG! Der Meister ist zu Gast',
      rank: 'Rang:',
      works_count: 'Anzahl der Werke:',
      book_now: 'Jetzt buchen',
      select_another_time: 'Andere Zeit auswählen',
    },
    success: {
      session: 'SITZUNG',
      become_mystery_shopper: 'Werden Sie Mystery-Shopper',
      add_to_calendar: 'Ereignis zum Kalender hinzufügen',
      call_on_day: '*ein Administrator des Salons wird Sie am Tag der Sitzung anrufen',
      return_to_menu: 'Zum Menü zurückkehren',
      recommended_products: 'Empfohlene Produkte zur Heilung',
    },
    black_friday: "50% Rabatt bei obligatorischer Anzahlung",
  },
  tips: {
    placeholder: 'Wählen Sie, wie das Trinkgeld aufgeteilt wird',
    divide_by_all: 'Gleichmäßig',
    administrator: 'An Administrator',
    parlor: 'An Salon',
    master: 'An Meister',
  },
  new_landing: {
    count_block: {
      countries: 'Anzahl der Länder',
      studios: 'Studios',
      awards: 'Auszeichnungen',
      masters: 'Meister',
    },
    header_banner: {
      label_1: 'Wir machen aus einer Mücke keinen Elefanten',
      label_2: 'wir machen Tattoos!',
      book_now: 'Jetzt buchen',
      consultation: 'Beratung',
      prices: 'Preise',
      top_works: 'Top Arbeiten',
      advantages: 'Vorteile',
      change_parlor: 'Salon wählen',
    },
    course_card: {
      theory_part: 'Theoretischer Teil:',
      practice_part: 'Praktischer Teil:',
      advance: 'Zusätzliche Vorteile:',
    },
    helpful_links: {
      for_masters: 'Für Meister',
      tattoo_news: 'Tattoo Nachrichten',
      tattoo_news_description:
        'Bleiben Sie auf dem Laufenden über das Wichtigste aus der Welt der Tattoos',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'Unsere Meister sind bereit, Wissen, Geheimnisse, Techniken zu teilen',
      rent_space: 'Platz mieten',
      rent_space_description:
        'Erfahren Sie mehr über die Kooperationsmöglichkeiten durch die Anmietung eines Arbeitsplatzes in den VEAN Studios',
      tattoo_promotions: 'Aktionen und Aktionen',
      tattoo_certificates: 'Geschenkgutscheine',
      consumables: 'Verbrauchsmaterialien',
      equipment: 'Ausrüstung',
    },
    price_block: {
      title: 'Preise',
      loyalty_program: {
        title: 'Treueprogramm',
      },
      actions: {
        book_now: 'Jetzt buchen',
        calculate_cost: 'Kosten berechnen',
      },
      // другие вложенные переводы...
    },
    top_works: {
      title: 'Top Arbeiten',
      from_the_world: 'aus aller Welt',
    },
    why_vean: {
      title: 'Warum VEAN',
      individual_approach: 'Individueller Ansatz',
      trust: 'Vertrauen',
      safety_guarantee: 'Sicherheitsgarantie',
      convenient_location: 'Bequeme Lage',
      visited_studio: 'Personen haben dieses Studio besucht',
    },
  },
  mystery_shopper: {
    title: 'Mystery Shopper',
    description:
      'Ein Mystery Shopper ist jemand, der die Funktion eines Geschäfts unter dem Deckmantel eines gewöhnlichen Kunden überprüft. Er bewertet den Servicelevel im Geschäft.',
    offer:
      'Wenn Sie sich entscheiden, für diese Sitzung ein Mystery Shopper zu werden, schenken wir Ihnen Münzen.',
    instructionTitle: 'Anleitung',
    instructionDetails:
      'Während der Sitzung müssen Sie das Geschäft und das Personal anhand verschiedener Kriterien bewerten. Nach der Sitzung erhalten Sie auch eine SMS mit einem Link zu einem Fragebogen, den Sie ausfüllen müssen (nur ehrlich!)',
    question: 'Möchten Sie ein Mystery Shopper werden?',
    yesButton: 'Ja',
    noButton: 'Nein, danke',
    successMessage: 'Sie müssen das Studio anhand der folgenden Kriterien bewerten:',
    criteria1: 'Telefon- und Social-Media-Beratung',
    criteria2: 'Beratung im Studio bei Ankunft',
    criteria3: 'Preispolitik des Studios',
    criteria4: 'Äußeres: Fassade des Studios, Schilder, Lage usw.',
    criteria5: 'Innenraum des Studios, Ambiente',
    criteria6: 'Produktsortiment, Souvenirs und Heilung (Schaufenster)',
    criteria7: 'Atmosphäre: TV, Musik, Personal',
    criteria8: 'Verfügbarkeit von Erfrischungen, Unterhaltung, Zeitschriften und Skizzenkatalogen',
    criteria9: 'Kommunikation mit dem Meister',
    criteria10: 'Kommunikation mit dem Administrator',
  },
  cookies: {
    text: `Wir verwenden Cookies, um Ihr Surferlebnis zu verbessern, 
      personalisierte Werbung oder Inhalte anzuzeigen 
      und unseren Datenverkehr zu analysieren. Durch Klicken auf „Akzeptieren“ 
      stimmen Sie der Verwendung von Cookies zu.`,
    confirm: 'Akzeptieren',
    more: 'Erfahren Sie mehr',
    manage: 'Verwalten',
    config: {
      necessary: 'Notwendig',
      preferences: 'Bevorzugt',
      statistics: 'Statistisch',
      marketing: 'Marketing',
    },
  },
  header_menu: {
    logout: 'Ausgang',
    main: 'Startseite',
    about_us: 'Über uns',
    discount_page: 'Rabatte',
    loyalty_page: 'Treueprogramm',
    ai_page_link: 'Skizze generieren',
    fonts_page_link: 'Tattoo-Schriftarten',
    quality_certificate: 'Qualitätszertifikate',
    confidence: 'Datenschutzrichtlinie',
    complaints: 'Beschwerden und Vorschläge',
    public_offer_link: 'Öffentliches Angebot',
    work: 'Ich möchte bei VEAN arbeiten',
    dockuments_page_link: 'Dokumente',
    login: 'Anmelden',
    account: 'Persönliches Büro',
    studying: 'Ich möchte lernen!',
    buy_certificate: 'Kaufen Sie ein Zertifikat',
    feedback: 'Rückmeldung',  
  },
  post: {
    welcome: 'Guten Tag. Sie waren bei unserer Tattoo-Session.',
    no_answer_questions: 'Sie haben die folgenden Fragen nicht beantwortet:',
    point: 'Bitte bewerten Sie die Servicequalität und erhalten Sie ein Geschenk!',
    send: 'senden',
    error: 'Ein Fehler ist aufgetreten!',
    not_found: 'Sitzung nicht gefunden!',
    not_call: 'Sie müssen nicht zurückrufen',
    thank: 'Vielen Dank für Ihre Bewertung!',
    bot: 'Nach 21 Tagen ruft Sie unser Bot von einer unbekannten Nummer an, um Informationen zur erbrachten Leistung und Heilung zu klären.',
    certificate: 'Ein Aktionsgutschein in Höhe von %{gift_price} VeAnCoins wurde für Sie erstellt.',
    social_networks: 'Bitte hinterlassen Sie eine Bewertung über uns in sozialen Netzwerken:',
    month:
      'Der Gutschein ist einen Monat lang gültig, vergessen Sie nicht, den Manager daran zu erinnern!',
    dissatisfied:
      'Sind Sie mit dem Service, den Sie erhalten haben, unzufrieden? Wir werden Ihre Anfrage so schnell wie möglich bearbeiten.',
    scores: {
      administrator: 'Bewertung der Arbeit des Managers:',
      master: 'Bewertung der Arbeit eines Tätowierers:',
      recommendations:
        'Ich wurde mündlich und schriftlich zur Pflege beraten: (Ответ да Ja/нет Nein)',
      care: 'Mir wurde angeboten, professionelle Pflege zu kaufen:',
      sketch: 'Mir wurde angeboten, eine individuelle Skizze / ein Projekt zu bestellen:',
      souvenir: 'Mir wurde angeboten, Souvenirs zu kaufen:',
      master_contact:
        'Hat der Meister Ihnen seine persönlichen Kontaktdaten hinterlassen (persönliche Telefonnummer, Link zu persönlichen sozialen Medien) oder haben Sie dem Meister Ihre eigenen hinterlassen?',
      repeat_record: 'Möchten Sie die Aufnahme wiederholen?',
    },
    scores_mystery_shopper: {
      instagram: 'Bewertung unseres INSTAGRAM',
      site: 'Bewertung der Website vean-tattoo.%{locale}',
      phone_consultation: 'Telefonische Beratung und in sozialen Netzwerken',
      consultation: 'Beratung im Studio bei der Ankunft',
      price: 'Preispolitik des Studios nach Ihrer Meinung',
      exterior: 'Exterieur: Studiofassade, Schilder, Standort usw.',
      interior: 'Studiointerieur, Umgebung',
      assortment: 'Sortiment von Souvenirs und Heilungsprodukten (Vitrine)',
      atmosphere: 'Atmosphäre: TV, Musik, Team',
      leisure: 'Verfügbarkeit von Bewirtung, Freizeit, Zeitschriften und Skizzenkatalogen',
      master: 'Kommunikation mit dem Meister',
      admin: 'Kommunikation mit dem Administrator',
      master_one_place: 'Arbeitet der Meister nur in diesem Studio? (ja/nein)',
      master_contact:
        'Bitten Sie um persönliche Kontaktdaten des Meisters, hat er sie zur Verfügung gestellt? (ja/nein)',
      care: 'Wurde Ihnen angeboten, Heilungsprodukte und unser Souvenirs zu kaufen, haben Sie ein kleines Geschenk erhalten: einen Magneten, einen Aufkleber oder einen Gutschein im Wert von 10% des abgeschlossenen Arbeitsbetrags für die nächste Dienstleistung? (ja/nein)',
      conclusion: 'Abschließende Bewertung der Überprüfung',
    },
    inputs: {
      cost: 'Sitzungskosten:',
      cost_other: 'Ich habe auch Schmuck / Pflegeprodukte gekauft für die Menge von:',
      review: 'Nachteile, die während der Sitzung festgestellt wurden:',
      photo: 'Фото салона',
    },
    inputs_mystery_shopper: {
      recommendation: 'Ihre Empfehlungen für uns',
      why_vean: 'Warum haben Sie VEAN ausgewählt?',
    },
  },
  header: {
    online_appointment: 'Terminanfrage',
    post_session: 'Ihre Qualitätsbewertung',
    get_price: 'Beratung',
    consultation: '(Konsultation)',
    order_consultation: 'Eine Beratung bestellen',
  },
  index: {
    parlor: 'Studio',
    master: 'Tätowierer',
    session_type: 'Dienstleistung',
    session_location: 'Körperstelle',
    datetime: 'Datum und Uhrzeit',
    create_appointment: 'Für einen Termin anmelden',
    bonus: '* BONUS %{customer_bonus} VeanCoins FÜR SELBSTREGISTRIERUNG',
    sketch: 'Skizze (optional)',
  },
  session_types: {
    tattoo: 'Tätowierung',
    tatouage: 'Permanent Make-Up',
    piercing: 'Piercing',
    stroke: 'Tattooentfernung',
    training_tattoo: 'Tattoo-Training',
    training_piercing: 'Piercing-Training',
    training_tattouage: 'Tattoo-Training',
    training_udder: 'Training zur Entfernung von Tätowierungen',
  },
  price_form: {
    client_name: 'Vor- und Nachname',
    client_phone: 'Telefonnummer',
    client_comm: 'Standort',
    sketch_desc: 'Skizze hinzufügen (optional)',
    sketch_button: 'Wählen Sie eine Datei aus',
    send: 'Senden',
    or: 'Oder',
    country: 'Land',
    city: 'Stadt',
    comment: 'Kommentar',
    val_error: 'Überprüfen Sie die eingegebenen Daten!',
    ths_message: 'Danke! Sie werden in Kürze kontaktiert',
    go_back: 'Zurück',
    file_size: 'Die Dateigröße darf maximal 5 MB betragen!',
  },
  selectors: {
    parlor: {
      placeholder: 'Nach Studionamen suchen...',
    },
    master: {
      placeholder: 'Suche nach Name, Dienstleistung...',
    },
    select_type: {
      select: 'Auswählen',
      next: 'Weiter',
      no_price: 'Preis nach Vereinbarung',
      ad: 'Von',
      da: 'Bis',
      selected: 'selected',
      info: 'Der Preis kann von der Größe und dem Standort abhängen.',
    },
    datetime: {
      select_date: 'Wählen Sie ein Datum aus',
      no_active_times:
        'Zu diesem Zeitpunkt sind keine Fenster mehr frei. Bitte wählen Sie eine andere!',
    },
  },
  verify: {
    verify_account: 'Konto verifizieren',
    type: 'Verifizierungsmethode',
    check_code: 'Code prüfen',
    status: 'Status',
    input_phone: 'Telefonnummer eingeben',
    email_help: 'Sie können auch E-Mail zur Verifizierung verwenden',
    sms_sent: 'Ein Code wurde Ihnen per SMS zugesandt, geben Sie ihn unten ein',
    resend_sms_after: 'Erneut senden in %{seconds} Sekunden',
    resend_sms: 'Nochmals senden',
    buttons: {
      back: 'Zurück',
      next: 'Weiter',
      create_appointment: 'Für einen Termin anmelden',
    },
  },
  success: {
    h1: 'Danke! Ihre Buchung ist erfolgreich!',
    h2: 'Nach Genehmigung des Termines durch den Manager wird Ihnen eine SMS zugesandt',
    mystery: 'Ein anonymer Käufer werden',
  },
  about: {
    header: 'Ihre Angaben',
    birth_info:
      'Durch die Eingabe Ihres Geburtsdatums stellen wir sicher, dass Sie volljährig sind und dass Sie einen Rabatt für Ihren Geburtstag erhalten :)',
    inputs: {
      name: 'Vorname und Nachname',
      find_out: 'Wie hast du uns herausgefunden',
      birth_date: 'Geburtsdatum',
    },
    buttons: {
      skip: 'Überspringen',
      back: 'Zurück',
      next: 'Weiter',
    },
  },
  footer: {
    header: 'Abonnieren Sie unsere sozialen Netzwerke',
    site_navigation: 'Navigation',
    interest: 'Interessantes',
    other: 'Sonstiges',
  },
  subscribe_modal: {
    header: 'Abonnieren Sie die Nachrichten für einen Bonus',
    submit: 'Senden',
    wrong_data: 'Überprüfen Sie die Korrektheit der eingegebenen Daten!',
    wrong_email: 'Falsche E-Mail-Adresse eingegeben!',
    thanks: 'Vielen Dank für die Anmeldung!',
    error: 'Ein Fehler ist aufgetreten!',
  },
  chat_bot: {
    call: 'Anrufen',
    online_record: 'Online Terminvereinbarung',
    online_consultation: 'Online Beratung',
    need_help: 'Brauchen Sie Hilfe bei einer Skizze?',
    order_sketch: 'Skizze bestellen',
    dress_sketch: 'Skizze anprobieren',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Problemfälle',
      id: 'ID',
      status: 'Status',
    },
    reset_password: {
      title: 'Passwort vergessen',
      submit: 'senden',
      forgot_password: 'Passwort vergessen',
      phone: 'Telefonnummer eingeben',
      success_message: 'Ein temporäres Passwort wurde an Ihre E-Mail/Telefon gesendet',
    },
    registration: {
      name: 'Name',
      surname: 'Nachname',
      registered: 'Registrierung',
      congratulations: 'Herzlichen Glückwunsch!',
      successfully_registered: 'Sie haben sich erfolgreich registriert!',
      log_in: 'Anmelden',
    },
    options: {
      no_interest_in_promotions: 'Ich bin nicht an Werbeaktionen und Boni interessiert',
      dislike_frequent_sms: 'Ich mag keine häufigen SMS-Nachrichten',
      already_have_tattoo:
        'Ich habe bereits ein Tattoo und habe nicht vor, bald ein weiteres zu machen',
      other: 'Sonstiges',
    },
    login_page: {
      log_in_page: 'Anmelden in Ihrem persönlichen Konto',
      number_missing: 'Haben Sie keine Nummer?',
      register: 'Registrieren Sie sich!',
      steps: {
        sign_up: 'Registrieren Sie sich für das Treueprogramm oder LOGIN',
        check_coins:
          'Überprüfen Sie Ihre VeanCoin, indem Sie sich für das Treueprogramm registrieren',
        get_coins: 'Erhalten Sie Ihre VeanCoins am Ende jeder Sitzung',
        accumulate: 'Sammeln und sammeln Sie VeanCoin in Ihrem Profil',
        book_session: 'Buchen Sie eine Sitzung und kommen Sie zu Vean, um Ihr Tattoo zu bekommen',
        exchange_coins: 'Tauschen Sie VeanCoins und erhalten Sie Rabatte',
      },
    },
    find_customer: {
      continue: 'Weiter',
      not_found: 'Kunde nicht gefunden!',
      select_type: 'Wählen Sie den Anmeldetyp',
      no_password: 'Sie haben kein Passwort festgelegt!',
      found: 'Gefunden!',
      check: 'Überprüfung...',
      invalid_phone_or_password: 'Ungültige Telefonnummer oder Passwort',
      error_login: 'Fehler bei der Anmeldung',
      phone: 'Telefon',
      temporary_password_message: 'Sie verwenden ein temporäres Passwort',
      permanent_password_message: "Sie verwenden ein permanentes Passwort",
    },
    product_card: {
      availability: 'Verfügbarkeit in den Studios:',
      unavailable: 'Nicht verfügbar',
    },
    store_body: {
      full_points_products: 'Artikel, die Sie mit VeanCoins kaufen können',
      half_points_products: 'Artikel, die Sie mit 50% Rabatt kaufen können',
      no_products: 'Keine Artikel',
    },
    description_block: {
      loyalty_system: 'VEAN Treue-System',
      show_more: 'Mehr anzeigen',
    },
    profile_card: {
      my_sketches: 'Meine Designs',
      share_link: 'Teile den link',
      tips: 'Trinkgeld',
      send_certificate: 'Zertifikat senden',
      vip: 'VIP',
      not_vip: 'VIP werden',
      veancoins: 'VeanCoins',
      balance_buy: 'Geld',
      active_certificates: 'Aktivierte Münzen',
      balance: 'Saldo',
      sessions: 'Sitzungen',
      points_alltime: 'Gesammelte Münzen insgesamt',
      available_certificates: 'Verfügbare Zertifikate',
      verify_account_status: 'Kontostatus',
      security_percent: 'Sicherheit:',
      certificates: 'Geldzertifikate',
      bought_certificates: 'Aktivierte VeanCoins',
      certificate_number: 'Zertifikatnummer',
      available_for: 'Gültig bis:',
      no_active_certificates: 'Keine Geldzertifikate',
      no_active_bought_certificates: 'Keine aktiven VeanCoins',
      coming_sessions: 'Kommende Sitzungen',
      complete_agreement: 'Vereinbarung ausfüllen',
      date: 'Datum:',
      you_was: 'Sie waren bei %{sessions} Sitzungen',
      edit: 'Bearbeiten',
      not_email: 'Um ein Zertifikat zu senden, muss eine E-Mail ausgefüllt werden',
      exist_application: 'Es gibt eine Anwendung',
      need_exist_application: 'keine Anwendung vorhanden',
      need_confirm_account: 'Konto muss bestätigt werden',
      verified: 'Verifiziert',
      choose_payment_method: 'Zahlungsmethode wählen',
      parlor: 'Salon',
      sign_up_online: 'Für eine Sitzung anmelden',
      mystery_shopper: 'Mystery Shopper',
      sign_up_secret_buyer:
        'Melden Sie sich bei uns für eine Sitzung an und werden Sie Mystery Shopper',
      pay: 'Bezahlen',
      prepayment: 'Vorauszahlung',
      questionnaire: 'Fragebogen',
      agreement: 'Vertrag',
      fill_required_information: 'Sie müssen die Informationen ausfüllen',
      buy: 'Guthaben aufladen',
      archive: 'Archiv',
      support_service: 'Kundensupport von Vean Tattoo Community',
      balance_buy_history: 'Verlauf der gekauften VeanCoins',
      balance_history: 'Verlauf der erhaltenen VeanCoins',
      buy_history: 'Kaufhistorie',
      сost: 'Kosten',
      payment_link: 'Zahlungslink',
      attributes: 'Attribute',
      invite_friend: 'Freund einladen',
      phone_or_email: 'Geben Sie eine Telefonnummer oder E-Mail-Adresse ein',
      report_problem: 'Problem melden',
      note: 'Einen Kommentar schreiben',
      tooltip_coins:
        'Sie müssen Ihre angesammelten Münzen für die gewählte Dienstleistung oder für den Kauf von Waren aktivieren, um deren Verbrennung innerhalb eines Jahres zu vermeiden',
      tooltip_certificate:
        'Anzahl Ihrer gekauften oder als Geschenk erhaltenen Geldzertifikate, die innerhalb von 2 Monaten verwendet werden können',
      tooltip_сonfirmed: 'Ändern Sie in der Bearbeitung «ФИО или username» zu «Vor- und Nachname»',
      tooltip_missing:
        'Fügen Sie Informationen über sich hinzu (Vor- und Nachname, Geburtsdatum, Link zu Ihren sozialen Netzwerken, wie Sie von uns erfahren haben, Telefonnummer und E-Mail zur Identitätsbestätigung)',
      tooltip_verified:
        'Sie müssen Ihr Konto per Telefonnummer oder E-Mail bestätigen, um Ihre verdienten und angesammelten VeanCoins zu schützen.',
      tooltip_vip:
        'VIP-Kunde ist ein Status, den jeder Kunde erhalten kann, der mehr als 1500 Euro für Dienstleistungen ausgegeben hat. Der VIP-Status bietet seinem Inhaber bestimmte Vorteile, wie z.B.: schnelle Prioritätsbuchung für Sitzungen außerhalb der Reihe, Zugang zu einzigartigen Waren und Dienstleistungen, nach Erreichen des VIP-Status erhält der Kunde sofort 1000 Münzen auf sein Konto gutgeschrieben',
      earn_veancoins: 'Verdiene veancoins', 
    },
    profile_edit: {
      additional_information: 'Zusätzliche Informationen über VeanCoins',
      parlors: 'Salon (der näher oder grundlegend ist)',
      client_card: 'Kundenkarte',
      full_name: 'Vollständiger Name oder Benutzername',
      birth_date: 'Geburtsdatum (für Glückwünsche)',
      social_media_link: 'Link zu sozialen Medien',
      find_out: 'Wie haben Sie davon erfahren',
      or: 'oder',
      phone_placeholder: 'Telefon',
      save: 'Speichern',
      password_tooltip:
        'Erstellen Sie ein Passwort, um in Zukunft auf Ihr Profil zugreifen zu können, ohne SMS oder E-Mails zu benötigen. Dies schützt auch Ihre persönlichen Daten.',
      password: 'Passwort',
      repeat_password: 'Passwort wiederholen',
      email_taken: 'Diese E-Mail-Adresse ist bereits vergeben!',
      phone_taken: 'Diese Telefonnummer ist bereits vergeben!',
      simple_password: 'Zu einfaches Passwort!',
      invalid_password: 'Ungültiges Passwort!',
      password_for_application: 'Passwort für die Anwendung (optional)',
      congratulate: 'Glückwunsch!',
      successfully_registered: 'Erfolgreich registriert!',
      need_confirm_account: 'Konto muss bestätigt werden',
      yes: 'Ja',
      no: 'Nein',
      phone_confirmed: 'Telefon bestätigt',
      email_confirmed: 'E-Mail bestätigt',
      change_password: 'Passwort ändern',
      old_password: 'Altes Passwort',
      new_password: 'Neues Passwort',
      confirm_password: 'Passwort wiederholen',
      invalid_phone_or_password: 'Ungültige Telefonnummer oder Passwort',
      error_login: 'Fehler bei der Anmeldung',
      years_old: 'Alter',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Geschlecht',
      man: 'Mann',
      woman: 'Frau',
      gender_hidden: 'Bevorzuge keine Angabe',
      language: 'Sprache',
      preferred_style_status: 'Bevorzugte Stile',
      classic: 'Klassik / Realismus',
      freak: 'Freak / Trash / Anime',
      fashion: 'Mode / Kunst / Minimalismus',
      rock: 'Rock / Hart',
      pop: 'Pop / Masse',
      accommodation_status: 'Unterkunftsstatus',
      local: 'Lokal',
      tourist: 'Tourist',
      guest: 'Gast',
      lifestyle_status: 'Lebensstil',
      extreme: 'Extrem',
      dance: 'Tanz',
      anime: 'Anime',
      game: 'Spiel',
      music: 'Musik',
      lgbt: 'LGBT',
      art: 'Kunst / Moderne',
      family: 'Familie / Liebe',
      beauty: 'Schönheit',
      sexuality: 'Sexualität',
      masculinity: 'Männlichkeit',
      tattoo_count_status: 'Anzahl der Tattoos',
      pierce_count_status: 'Anzahl der Piercings',
      one: 'Eins',
      two: 'Zwei oder mehr',
      inc: 'Sehr viele',
      change_information: 'Informationen speichern',
    },
    verification: {
      sms: 'Bestätigen per SMS',
      email: 'Bestätigen per E-Mail',
      google: 'Überprüfen per Google',
      send_code: 'Code senden',
      code: 'Verifizierungscode',
    },
    loyalty: {
      submit_button: 'Senden',
      bonus_instruction:
        'Führen Sie eine der folgenden Aufgaben aus, legen Sie Beweise (Screenshots, Fotos usw.) vor und erhalten Sie einen Bonus von',
      bonus_amount: 'VeanCoins',
      review_option_1:
        'Hinterlassen Sie eine ausführliche Bewertung im Profil des Meisters auf der Website',
      review_option_2: 'Bewerten Sie das Studio oder den Meister auf der Website',
      review_option_3:
        'Bewerten Sie das Studio und hinterlassen Sie eine Bewertung auf Google Maps',
      review_option_4: 'Teilen Sie unser Studio in sozialen Medien',
      return:
        'Wenn das von Ihnen gesendete Foto keiner der Kategorien entspricht, für die wir Boni vergeben, werden die erhaltenen Boni bei Kauf eines Geschenkgutscheins storniert.',
      evidence_label: 'Beweise',
    },
    buy_certificate: {
      payment_type_title: 'Art des Kaufs',
      payment_type_subtitle: 'Was möchten Sie tun',
      buy_balance: 'Guthaben aufladen',
      buy_certificate: 'Zertifikat kaufen',
      amount_title: 'Betrag',
      amount_subtitle: 'Betrag eingeben',
      payment_method_title: 'Zahlungsmethode',
      payment_method_subtitle: 'Wählen Sie eine Zahlungsmethode',
      payment_title: 'Zahlung',
      forming_payment: 'Zahlung wird gebildet...',
      pay: 'Bezahlen',
      continue: 'Fortsetzen',
      back: 'Zurück',
      submit_to_email: "Zertifikat per E-Mail senden",
      get_vean_card: "VEAN-Karte im Studio erhalten",
      vean_card_description: "Die VEAN CARD ist Ihre Karte, auf der VEAN TATTOO-Zertifikate automatisch aktiviert werden und die Sicherheit und Komfort gewährleistet. Alle Ihre Geschenke und Rabatte sind immer griffbereit, und die Nutzung der Karte ist so einfach und vorteilhaft wie möglich. Holen Sie sich Ihre Karte in den VEAN TATTOO-Studios und entdecken Sie noch heute alle Vorteile!",
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Über 150 Salons',
      clients_count: 'Über 1.000.000 Kunden',
      largest_company: 'Das größte Netzwerk von Tattoo-Studios weltweit',
    },
    master_block: {
      our_masters: 'Unsere Meister',
    },
    master_card: {
      works_count: 'Anzahl der Arbeiten: ',
    },
    no_admin_block: {
      leisure: 'Freizeit',
      session_record: 'Für eine Sitzung anmelden',
      about_company: 'Über uns',
      care: 'Pflege',
      quality_certificates: 'Qualitätszertifikate',
      public_buyout_offer: 'Öffentliches Angebot',
      complaints_offers: 'Kritik und Anregungen',
      loyalty_system: 'Sicherheitssystem',
      discount_page: 'Rabatte',
    },
    peculiarities_block: {
      about_studio: 'Über dieses Studio',
      clients: 'Kunden',
      sessions: 'Sitzungen',
      studio_hours: 'Öffnungszeiten: ',
      studio_phone: 'Studio-Nummer: ',
      wifi: 'Wi-fi: ',
      password: 'Passwort: ',
      from_to: 'von %{time_start} bis %{time_end}',
    },
    photos_block: {
      photos: 'Foto des Salons',
    },
    top_works: 'Top-Arbeiten',
    care_important: 'Das Wichtigste bei Piercing und Tätowierung ist die Heilung und Pflege.',
    cabinet: 'Persönliches Schließfach',
    complete_agreemnent: 'Vollständige Vereinbarung',
    is_blanket: 'Es gibt eine Decke, um sich warm zu halten',
    is_water: 'Es gibt heiße Getränke',
    is_hot_drinks: 'Es gibt Süßigkeiten',
    is_treats: 'Es gibt kalte Getränke',
  },
  promotion: {
    submit: 'Senden',
    warning: 'Achtung! Überprüfen Sie die Richtigkeit der eingegebenen Daten!',
    name: 'Name / Spitzname',
    required: 'Dieses Feld ist erforderlich!',
    max_value: 'Darf nicht mehr als 100 Zeichen enthalten!',
    phone: 'Telefon',
    social_link: 'Link zu sozialen Medien',
    wrong_format: 'Falsches Format!',
    terms: 'Ich habe die Datenschutzrichtlinie gelesen und stimme zu',
    know_bonus: 'Mir ist bekannt, dass mir Bonuspunkte gutgeschrieben werden',
    error: 'Ein Fehler ist aufgetreten',
    thanks: 'Vielen Dank!',
    bonus: 'Ihnen wurden %{gift_price} Punkte gutgeschrieben',
    do_not_disturb: 'Nicht stören (SMS und Anrufe von VeanTattoo deaktivieren)',
    get_promo: 'Ich möchte Informationen über Rabatte und Aktionen erhalten',
    first_time_veancoins_notification:
      'Um VeanCoins zum ersten Mal zu verwenden, muss man sich an den Administrator des Studios wenden. VeanCoins und Geld haben unterschiedliche Nutzungsregeln',
  },
  another_studio_drawler: {
    another: 'Andere Studios',
  },
  landing: {
    best_studio: 'Das Tattoostudio in Ihrer Stadt',
    vean_wide: 'VEAN TATTOO-Netzwerk',
    studio_count: 'Über 150 Tattoostudios',
    quality: 'Qualitäts- und Sicherheitsgarantie',
    get_price: 'PREIS ERFAHREN',
    contact_us: 'Kontaktieren Sie uns',
    advantage: 'Vorteile',
    top_works: 'Топ работ',
    prices: 'Preise', //долджен быиь
    studio_photo: 'Studiobilder',
    prices_services: 'Preise und Dienstleistungen',
    about: 'Über uns',
    loyalty_system: 'Kundenbindungsprogramm', // sollte sein
    why_vean: 'Warum VEAN',
    visit_count: 'Menschen haben dieses Studio besucht',
    description:
      'Das VeAn Tattoo-Netzwerk ist heute das größte seiner Art weltweit: Mit über 150 Studios in 14 europäischen Ländern haben wir diese Branche auf völlig neue, höhere Standards gehoben. Die Spezialisierung des Netzwerks ist breit gefächert, aber der Schwerpunkt liegt auf der Tattoo- und Piercing-Industrie. Die Schlüsselprinzipien von VeAn Tattoo sind: Fachwissen und Zugänglichkeit: Wir streben danach, dass sich jeder Kunde in jeder Phase des Prozesses wohl fühlt, daher sind unsere Experten bereit, alle Ihre Fragen online zu beantworten. Ruf und kundenorientierte Ausrichtung: Jeder Kunde ist die Möglichkeit, ein einzigartiges Kunstwerk auf ihrer Haut zu schaffen. Wir schätzen Ihre Zeit und bemühen uns, dass Sie unser Studio mit besserer Stimmung und erstaunlichen Tattoos verlassen. Wir kümmern uns um unseren Ruf und schätzen jeden Kunden im Netzwerk. Wert und Zugänglichkeit: Wir bieten das perfekte Verhältnis von Preis und Qualität. Unsere Dienstleistungen sind für ein breites Publikum zugänglich, und dennoch opfern wir keine Qualität. Zertifizierte und hochwertige Ausrüstung: Unsere Materialien entsprechen den höchsten Standards für Sicherheit und Qualität. Wir garantieren, dass unsere Kunden nur das Beste erhalten. Hochqualifizierte Fachleute und ein breites Dienstleistungsangebot: In den VeAn Tattoo-Studios arbeiten ausschließlich diplomierte Meister, die weltweit geschätzt und respektiert werden. Wir bieten alle vorhandenen Tattoo-Stile und -techniken an, entwickeln kostenlos individuelle Skizzen nach Ihren Anforderungen. Außerdem laden wir Sie ein, alle unsere offiziellen Seiten in sozialen Netzwerken zu abonnieren. Dies ermöglicht es Ihnen, über Sonderangebote, Aktionen, Wettbewerbe, Arbeiten von Meistern, die neuesten Nachrichten und Updates von VeAn Tattoo auf dem Laufenden zu bleiben.',
    advantages: {},
    our_masters: 'Unsere Meister', //doljen bit
    from: 'Von',
    no_price: 'Preis nach Vereinbarung',
    form: {
      get_price_consultation: 'Preis erfahren / Beratung',
      name: 'Vorname',
      phone: 'Telefonnummer',
      place: 'Ihr Ort',
      subscribe_news: 'Abonnieren Sie unseren Newsletter für einen Bonus',
    },
  },
  form_messages: {
    submit: 'Senden',
    warning: 'Achtung! Überprüfen Sie die Richtigkeit der eingegebenen Daten!',
    required: 'Dies ist ein Pflichtfeld!',
    length: 'Nicht mehr als %{length} Buchstaben!',
    file_length: 'Nicht mehr als %{length} Dateien!',
    wrong_format: 'Falsches Format!',
    error: 'Es ist ein Fehler aufgetreten',
    thanks: 'Vielen Dank!',
    required_label: 'Erforderlich',
    not_required_label: 'Optional',
  },
  training: {
    training: 'AUSBILDUNG',
    tattoo_school: 'TATTOOSCHULE',
    biggest_school: 'das größte Netzwerk von Tattoostudios in der Welt',
    ne_otkladivaj: 'Verschiebe<br/>dich selbst<br/>nicht auf später',
    learn: 'Lerne zu tätowieren<br/>und werde ein Top-Meister',
    reviews: 'Bewertungen von Absolventen',
    record: 'Anmelden',
    video_text: 'Wie können Sie helfen,<br/>was werden Sie lehren?',
    ded: {
      consultation: 'Beratung',
      get_more: 'Erfahren Sie mehr',
    },
    header_bar: {
      main: 'Hauptseite',
      get: 'Was Sie bekommen',
      program: 'Kursprogramm',
      about: 'Über uns',
      reviews: 'Bewertungen',
    },
    our_course: {
      head: 'Unsere<br/>Kurse',
      description_1: 'hier bekommst du immer eine Antwort auf deine Fragen und lernst etwas Neues',
      description_2:
        'Und selbst wenn du keinen künstlerischen Hintergrund hast – das Ergebnis lässt nicht auf sich warten',
      label: '[ bei uns ist es warm, gemütlich und aufregend ]',
      description_3:
        'Wir lieben unsere Schüler und versuchen alles, damit der Lernprozess so angenehm wie möglich ist',
    },
    banner: {
      we_teach: 'Wir bringen Dir bei, Tattoos zu stechen',
      good_make: 'WIR STECHEN COOLE TATTOOS',
      our_masters: 'UNSERE MEISTER SIND BEREIT, IHR WISSEN, GEHEIMNISSE, TECHNIKEN ZU TEILEN',
      learn_too: 'Und wir bringen es auch dir bei',
    },
    modal: {
      thanks: 'Danke!',
      error: 'Ein Fehler ist aufgetreten!',
      complete: 'Füllen Sie das Formular aus, wir werden Sie anrufen',
      full_name: 'Vollständiger Name',
      phone: 'Telefonnummer',
      submit: 'Absenden',
      cancel: 'Abbrechen',
    },
    paragraph: {
      header_1: 'Wir lehren definitiv',
      content_1: 'Wir machen keine Massenausbildung',
      header_2: 'zeichnen können',
      content_2: 'Wir lehren dich alles / keine künstlerische Bildung ist überhaupt kein Problem',
      header_3: 'Zertifikat',
      content_3:
        'Nach Abschluss der Kurse erhalten Sie ein Zertifikat nach internationalem Standard. Genehmigt von Global Tattoo ACC',
      header_4: 'Wir beschäftigen',
      content_4:
        'OMG, meinst du das ernst? Ja, die besten Absolventen können Teil unseres Teams werden und in ganz Europa arbeiten',
      header_5: 'Vertrag',
      content_5: 'Wir arbeiten nicht auf "Ehrenwort". Alles ist offiziell und vertraglich geregelt',
      no_sleep: {
        header: 'nicht verschlafen',
        rect_1: 'Wie?',
        rect_2: 'LEBEN',
        main_label: 'Beginne jetzt zu lernen',
        label_1: '1000+ Absolventen',
        label_2: '100% haben die Ausbildung abgeschlossen',
        label_3: '80% wurden eingestellt',
        label_4: '300+ Partner in der Tattoo-Industrie',
      },
    },
    principies: {
      head: 'Garantien',
      label_1: '1. Kultur',
      label_2: '2. Qualität',
      label_3: '3. Methoden',
      heading: 'Alles basiert auf Prinzipien',
      description_1:
        'Vor 11 Jahren haben wir beschlossen, die Kultur der Tattoo-Industrie zu entwickeln, nicht wie alle anderen, auf unsere eigene Weise - basierend auf Wissen und Erfahrung',
      description_2:
        'Heute haben wir 1000+ ausgezeichnete Tätowierer ausgebildet. 80% wurden Teil unseres Teams. Wir können mit Zuversicht sagen, dass jeder das Tätowieren lernen kann',
      description_3:
        'Es geht um die Methode, die wir verwenden, die wir ständig zusammen mit dem Team, den Künstlern und unseren Absolventen, die regelmäßig Feedback geben, verbessern',
    },
    packages: {
      head: 'Kursprogramme',
      from: 'von',
      plus: 'Vorteile',
    },
    facilities: {
      head: 'Möglichkeiten und Vorteile',
      subhead: 'Wählen Sie unsere Kurse und spüren Sie die Magie der Tattoo-Industrie',
      card: {
        head_1: 'Jeder unserer Absolventen erhält ein Zertifikat nach internationalem Standard',
        label_1: '- dein Ticket in eine Welt voller Möglichkeiten',
        head_2: 'Empfehlung und Unterstützung von einer Gruppe professioneller Meister',
        label_2: 'Dein Erfolg ist unser Stolz!',
        head_3: 'Tattoo-Gemeinschaft, erhalte Ratschläge und Unterstützung von unseren Experten',
        label_3: 'Dein neues Leben in der Welt der Tattoos beginnt jetzt!',
      },
    },
    review: {
      head_1: 'Fälle von Meistern',
      head_2: 'Bewertungen',
      comment_1:
        'Die Tattoo-Ausbildung in dieser Schule hat alle meine Erwartungen übertroffen! Die Lehrer sind Profis in ihrem Fach, jeder Ausbildungsschritt wird gründlich besprochen, die Atmosphäre ist großartig. Jetzt kann ich meine kreativen Ideen selbstbewusst auf der Haut der Kunden umsetzen!',
      comment_2:
        'Danke an die Schule für solch eine qualitativ hochwertige Tattoo-Ausbildung! Es war anfangs ein wenig beängstigend, sich darauf einzulassen, aber dank der aufmerksamen und geduldigen Lehrer fühlte ich mich sicher. Jetzt erhalten meine Arbeiten Anerkennung und Bewunderung!',
      comment_3:
        'Es war eine unglaubliche Reise in die Welt der Tattoos! Die Schule bietet alle notwendigen Kenntnisse und Fähigkeiten, um ein echter Profi zu werden. Ich bin dankbar für die Unterstützung und Inspiration, die ich hier erhalten habe',
    },
  },
  questionary: {
    first: {
      heading: 'EINWILLIGUNG FÜR VERFAHREN BEI VEAN TATTOO STUDIO',
      textbox:
        'Wenn der Kunde minderjährig ist, muss die Einwilligung von den Eltern oder gesetzlichen Vormündern ausgefüllt werden (In British Columbia, Kanada, gilt eine Person unter 19 Jahren als minderjährig. Wenn Sie unter 19 sind, wählen Sie bitte die ZWEITE Option)',
      has_18: 'JA, ich bin 18 Jahre alt oder älter',
      no_18: 'NEIN, ich bin unter 18',
    },
    second: {
      warning:
        'Achtung! Am Tag der Sitzung ist es notwendig, mit Erziehungsberechtigten zu kommen!',
      name: 'Vollständiger Name',
      birthdate: 'Geburtsdatum',
      address: 'Wohnadresse',
      email: 'E-Mail',
      sex: 'Geschlecht',
      heading: 'DURCHZUFÜHRENDES VERFAHREN',
      man: 'Männlich',
      woman: 'Weiblich',
      other: 'Nicht angegeben',
      parent_data: 'Daten eines der Elternteile:',
      child_data: 'Daten eines MINDERJÄHRIGEN:',
      child_field_placeholder: 'Sohn, Tochter, etc.',
      child_field_label: 'Für wen wird das Verfahren durchgeführt',
      confirm:
        'Ich, als gesetzlicher Vertreter eines Minderjährigen, gebe meine Zustimmung zur Durchführung des folgenden Verfahrens für mein Kind.',
    },
    third: {
      heading: 'ALLES, WAS SIE ÜBER DIE HEILUNGSPHASE WISSEN MÜSSEN:',
    },
    fourth: {
      nothing: 'Keines der oben genannten',
      has_diseases: 'Haben Sie irgendwelche Krankheiten?',
      has_contraindications: 'Haben Sie irgendwelche Gegenanzeigen?',
      heading1:
        'WENN SIE EINE DER FOLGENDEN KRANKHEITEN HABEN, MÜSSEN WIR ES WISSEN, BITTE MARKIEREN SIE DIE ENTSPRECHENDEN PUNKTE:',
      heading2: 'WENN GEGENANZEIGEN VORLIEGEN, BITTE DIE ENTSPRECHENDEN INFORMATIONEN ANGEBEN:',
    },
    fifth: {
      heading: 'NACH DEM VERFAHREN VERBOTEN:',
    },
    sixth: {
      heading: 'WO HABEN SIE VON UNS ERFAHREN:',
      label:
        'Nach 21 Tagen kann sich einer unserer Mitarbeiter im Rahmen einer Qualitätsüberprüfung der erbrachten Dienstleistungen mit Ihnen in Verbindung setzen. Darüber hinaus erhalten Sie nach der Sitzung SMS-Benachrichtigungen, und es ist möglich, dass unser automatisierter Roboter einen Anruf tätigt. In Ihrem persönlichen Konto können Sie sich von den Benachrichtigungsdiensten per Anruf und SMS abmelden und alle Ihre Daten und Fotos nach 21 Tagen ab dem Datum der Dienstleistung löschen.',
    },
    seventh: {
      heading: 'ERKLÄRUNG UND EINWILLIGUNG',
    },
    signature: {
      heading:
        'Ich, der Unterzeichnete (%{name}), erteile hiermit meine Zustimmung zum heutigen Verfahren mit meiner Teilnahme\n' +
        '%{session_type} auf meinem Körper und stimme den Bedingungen in diesem Dokument zu.',
    },
    next: 'Weiter',
    submit: 'Einreichen',
    no_responsibility: 'Das Studio ist nicht verantwortlich für diese Sitzung',
    acquaint: 'Ich habe die mir bereitgestellten Informationen gelesen',
    acquaint_2:
      'Ich verstehe, dass Vean Tattoo nicht für den Heilungsprozess verantwortlich ist.\n' +
      'Im Falle einer Korrektur des oben genannten Tattoos oder Piercings betragen die Kosten für die Korrektur 30 % der Kosten der eigentlichen Dienstleistung.\n' +
      'Wenn Sie mit dem Ergebnis der Korrektur nicht zufrieden sind, wird in diesem Fall kein Geld für die Dienstleistung zurückerstattet, da die oben genannten Bereiche nicht günstig für die Durchführung der Dienstleistung sind.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Keine Lösung',
      in_check: 'In Überprüfung',
      problem_solved: 'Problem gelöst',
      wait: 'Warten',
      small: 'Kleine Situation',
      unanswered_check: 'Überprüfung ohne Antwort',
    },
  },
  master_page: {
    card: {
      active: 'Aktiv',
      not_active: 'Nicht aktiv',
      master_since: 'Meister VEAN seit',
      services: 'Dienstleistungen',
      styles: 'Stile',
      book: 'Buchen',
    },
    information: {
      top_works: 'Beste Arbeiten',
  awards_and_diplomas: 'Auszeichnungen und Diplome',
    },
  },
  stripe_warning:
    'Achtung! Der Mindestzahlungsbetrag mit stripe beträgt 20€. Wenn ein kleinerer Betrag erforderlich ist, wenden Sie sich bitte an das Studio für weitere Informationen',
  check_in: {
    welcome_message: 'WIR WÜNSCHEN IHNEN EINE ANGENEHME SITZUNG!',
    session_started: 'IHRE SITZUNG HAT BEGONNEN',
    next_steps: 'Nach Abschluss der Sitzung werden Ihnen weitere Schritte zur Verfügung stehen',
    buy_healing_product: 'WIR EMPFEHLEN DEN KAUF EINES HEILMITTELS',
    skip: 'Überspringen',
    leave_review: 'Bitte hinterlassen Sie eine Bewertung',
    your_impression: 'Was sind Ihre allgemeinen Eindrücke?',
    add_photo: 'Fügen Sie ein Foto der Arbeit hinzu, um zusätzliche 1 Vean Coin zu erhalten',
    send: 'Senden',
    payment_success: 'Die Zahlung war erfolgreich',
    get_invoice: 'Möchten Sie eine Rechnung erhalten?',
    invite_friend: 'Freund einladen',
    recommend_us: 'Empfehlen Sie uns Ihren Freunden und verdienen Sie gemeinsam mit ihnen',
    thank_employees: 'Wir empfehlen auch, unseren Mitarbeitern zu danken',
    you_can_tip:
      'Lieber Nikita, Sie können unseren Mitarbeitern auch mit einer bestimmten Menge Trinkgeld danken',
    own_amount: 'Eigener Betrag',
    who_to_tip: 'Wem sollte das Trinkgeld zugewiesen werden?',
    payment_type: 'Zahlungsart',
    continue: 'Fortsetzen',
    summary: 'Zusammenfassung',
    healing_agent: 'Heilmittel',
    tips: 'Trinkgeld',
    session_payment: 'Sitzungszahlung',
    total: 'Gesamt',
    waiting: 'Warten...',
    available_funds: 'Verfügbare Mittel',
    refill_balance: 'Guthaben aufladen',
    go_to_payment: 'Zur Zahlung gehen',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Trinkgeld',
    prepayment: 'Vorauszahlung',
    have_questions: 'Haben Sie Fragen?',
    title: 'Sitzungen',
    selectStatus: 'Status auswählen',
    selectParlor: 'Salon auswählen',
    loading: 'Laden...',
    fromDate: 'Ab Datum',
    toDate: 'Bis Datum',
    search: 'Suchen',
    all: 'Alle',
    status: {
      100: 'Ausstehend',
      200: 'Neu',
      300: 'In Bearbeitung',
      400: 'Abgeschlossen',
      500: 'Storniert',
      600: 'Warten auf Zahlung',
    },
  },
  master: {
    rank: 'Rang',
    works_count: 'Anzahl der Arbeiten',
    address: 'Adresse',
  },

  modalPhone: {
    title: "Achtung!",
    info: "Sehr geehrter Kunde, zu Ihrer Sicherheit und für eine effektive Nutzung unseres Services ist das Hinzufügen aller Daten erforderlich. Dies hilft uns, zeitnah auf wichtige Fragen zu reagieren und Ihre Sicherheit zu gewährleisten.",
    reminder: "Erinnern Sie mich später",
    go_to_settings: "Gehe zu Einstellungen",
    roughly_price: "Der ungefähre Preis hängt von der Genauigkeit der von Ihnen eingegebenen Daten ab",
    success_message: 'Vielen Dank!',
  },
  studyingRegistration: {
    become_student: "Werden Sie Student",
    step_1: "Allgemeine Daten", 
    step_2: "Persönliche Informationen",
    step_3: "Studieninformationen",
    city: "Stadt",
    city_placeholder: "Geben Sie Ihre Stadt ein",
    name: "Vorname",
    name_placeholder: "Geben Sie Ihren Vornamen ein",
    last_name: "Nachname",
    last_name_placeholder: "Geben Sie Ihren Nachnamen ein",
    date_of_birth: "Geburtsdatum",
    date_of_birth_placeholder: "Geben Sie Ihr Geburtsdatum ein",
    phone: "Telefonnummer",
    email: "E-Mail",
    email_placeholder: "Geben Sie Ihre E-Mail ein",
    social_media: "Soziale Medien",
    social_media_placeholder: "Link zu Ihrem Profil",
    referral_source: "Wie haben Sie von uns erfahren?",
    referral_source_placeholder: "Wählen Sie eine Quelle",
    study_type: "Studienart",
    study_type_placeholder: "Wählen Sie die Studienart",
    start_date: "Voraussichtliches Startdatum",
    start_date_help: "Geben Sie das voraussichtliche Startdatum ein",
    hours_number: "Anzahl der Stunden",
    practice_number: "Anzahl der Praktiken",
    details: "Details",
    education_price: 'Ausbildungspreis',
  },
  studying_main: {
    become_student: "Werden Sie Student!",
    consultation: "Beratung",
    want_to_study: "Ich möchte studieren!",
    consult_with_our_advisor: "Wissen Sie nicht, wo Sie anfangen sollen? Kontaktieren Sie unseren Berater!"
  },
  errorTicket: {
    sidebar_title: "Fehler melden",
    main_title: 'Fehlerticket erstellen',
    basic_info:
      'Bei der Erstellung eines Tickets ist es wichtig, alle notwendigen Informationen bereitzustellen, um Ihre Anfrage schnell und effizient zu bearbeiten. Hier ist eine beispielhafte Struktur eines Berichts, die Ihnen helfen kann:',
    ticket_title: 'Ticketüberschrift:',
    short_description:
      "Kurze Beschreibung des Problems (z. B. 'Probleme bei der Erstellung von Sitzungen')",
    first_title: 'Problembeschreibung:',
    detailed_description: 'Detaillierte Beschreibung:',
    problem_description:
      'Beschreiben Sie das Wesen des Problems, was genau passiert und worin die Störung besteht.',
    reproduction_steps: 'Schritte zur Reproduktion des Problems:',
    reproduction_steps_description: 'Listen Sie die Schritte auf, die zum Problem führen.',
    expected_result: 'Erwartetes Ergebnis:',
    expected_result_description: 'Was haben Sie erwartet zu erhalten oder zu sehen?',
    actual_result: 'Tatsächliches Ergebnis:',
    actual_result_description: 'Was ist passiert, anstatt das erwartete Ergebnis?',
    important_data: 'Wichtige Daten:',
    links: 'Links:',
    links_description: 'Geben Sie Links zu problematischen Seiten oder Dokumenten an.',
    photos_and_screenshots: 'Fotos und Screenshots:',
    photos_and_screenshots_description:
      'Fügen Sie Bilder hinzu, die helfen können, das Problem zu visualisieren.',
    session_numbers: 'Sitzungsnummern:',
    session_numbers_description:
      'Geben Sie die Sitzungsnummern an, während derer das Problem aufgetreten ist.',
    additional_info: 'Zusätzliche Informationen:',
    additional_info_description:
      'Fügen Sie zusätzliche Informationen hinzu, die nützlich sein könnten.',
    priority_and_urgency: 'Priorität und Dringlichkeit:',
    priority_and_urgency_description:
      'Wenn das Problem Ihre Arbeit erheblich beeinträchtigt, geben Sie dies hier an und fordern Sie eine hohe Priorität an.',
    issue_title: 'Überschrift',
    issue_title_placeholder: 'Geben Sie eine kurze Beschreibung des Problems ein',
    description: 'Beschreibung',
    description_placeholder: 'Beschreiben Sie das Problem ausführlicher',
    files: 'Dateien',
    drag_and_drop: 'Ziehen Sie die Datei hierher oder wählen Sie aus',
    uploaded_files: 'Hochgeladene Dateien',
    create_button: 'Erstellen',
    problem_type: 'Problemtyp',
    select_problem_type: 'Wählen Sie einen Problemtyp',
    technical: 'Technisch',
    non_technical: 'Nicht-technisch',
    loading: "Wird geladen...",
    main_page: "Zur Hauptseite gehen"
  },
  multistep: {
    basic_info: "Grunddaten",
    type: 'Typ',
    summary: 'Zusammenfassung',
    details_rent: "Mietdetails",
    confirmations: "Zustimmungen",
    open_google: "In Google Maps öffnen",
  },
  check_in_view: {
    important: 'Wichtig',
    log_in_state_greet: 'Melden Sie sich in Ihrem Konto an, um Ihre Boni und Rabatte zu verfolgen.',
    log_in: 'Anmelden',
    download_app_greet: 'Laden Sie unsere App herunter und erhalten Sie einen Bonus',
    download: 'Herunterladen',
    welcome: 'Willkommen',
    studio_info: 'Studio-Informationen',
    recommended_products: 'Empfohlene Produkte',
  }
};
