import axios from '@/plugins/axios';
import { EFormActionType } from '@/types/enums';
import { APIURL } from '@/utils/url';

type TCreateFormActionData = {
  action_type: EFormActionType;
  form_link: number; // Form link ID
};

const createFormAction = async (data: TCreateFormActionData) => {
  await axios.post(`${APIURL}/v2/form-actions/`, data);
};

export { TCreateFormActionData, createFormAction };
