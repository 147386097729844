import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';
import { TCustomerLogMeta } from '@/types/types';

type TCreateLogData = {
  name: string,
  customer?: number,
  meta: TCustomerLogMeta
}
const createLog = async (data: TCreateLogData) => {
  const response = await axios.post(`${APIURL}/v1/customer-actions/`, data);
  return response.data;
};

const createLogBeacon = (data: TCreateLogData) => {
  console.log('beakon senddd')
  const formData = new FormData();
  formData.append("name", data.name);
  if (data.customer !== undefined) {
    formData.append("customer", data.customer.toString());
  }
  formData.append("meta", JSON.stringify(data.meta));
  navigator.sendBeacon(`${APIURL}/v1/customer-actions/`, formData);
};


type TUpdateLogData = {
  customer: number,
  meta: TCustomerLogMeta
}
const updateLog = async (data: TUpdateLogData) => {
  const response = await axios.patch(`${APIURL}/v1/customer-actions/`, data);
  return response.data;
};

const deleteLog = async (id: number) => {
  const response = await axios.delete(`${APIURL}/v1/customer-actions/${id}`);
  return response.data;
};

export {
  createLog,
  createLogBeacon,
  updateLog,
  deleteLog,
};