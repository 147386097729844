export default {
  support: {
    problem_situation_link: 'Mul on pärast sessiooni probleem',
    other_problem_link: 'Mul on teine probleem',
    problem_situation: {
      header: 'Probleem pärast sessiooni',
      form: {
        session: 'Sessioon',
        created_by_comment: 'Kirjeldage probleemi',
      },
    },
    complain: {
      header: 'Kaebused ja ettepanekud',
      form: {
        name: 'Nimi',
        phone_number: 'Telefoninumber',
        email: 'E-Mail',
        parlor: 'Salong',
        description: 'Kirjeldage probleemi',
        feedback: {
          overall_quality: 'Kuidas hindate meie salongi teenuse üldist kvaliteeti?',
          professional_skills: 'Kas jäite rahule meistri professionaalsete oskustega?',
          staff_politeness: 'Kui viisakas ja tähelepanelik oli salongi personal?',
          waiting_time: 'Kas olite oma teenuse ooteajaga rahul?',
          staff_professionalism: 'Kuidas hindate meie personali professionaalsust?',
          salon_cleanliness: 'Kuidas hindate meie salongi puhtust ja mugavust?',
          service_value: 'Kas arvate, et teenuste maksumus vastab nende kvaliteedile?',
          recommend: 'Kas soovitaksite meie salongi oma sõpradele ja tuttavatele?',
        },
      },
    },
  },
  items_view: {
    header: 'Tooted',
    filter_form: {
      name_placeholder: 'Nimi',
      category_placeholder: 'Kategooria',
      parlor_placeholder: 'Salong',
      all_categories: 'Kõik kategooriad',
      all_parlors: 'Kõik salongid',
    },
    load_more_button: 'Laadi rohkem',
    not_found_message: 'Ei leitud midagi',
  },
  item_view: {
    error_message: 'Andmete laadimisel tekkis viga',
    available_in_parlor: 'Saadaval teie salongis',
    not_available_in_parlor: 'Pole saadaval teie salongis',
    similar_items_label: 'Sarnased tooted',
    log_in_to_buy: 'Ostu sooritamiseks logi sisse',
  },
  item_simple_card: {
    studio_price_label: 'Stuudio hind',
    shop_price_label: 'Poja hind',
  },
  student_candidate: {
    form: {
      who_studing: 'Õpilane või koha rentimine',
      phone_number: 'Telefoninumber',
      first_name: 'Eesnimi',
      last_name: 'Perekonnanimi',
      social_link: 'Link sotsiaalvõrgustikku',
      parlors: 'Stuudio',
      couch: 'Tattoo tool',
      find_out: 'Kuidas sa meie kohta teada said?',
      start_date: 'Soovitud alguskuupäev',
      types: 'Seansitüübid',
      work_permit_document: 'Tööluba',
      sanitary_book: 'Sanitaaraamat',
      identity_proof: 'Isikut tõendav dokument',
      work_photos: 'Tööfotod',
      end_date: 'Soovitud lõppkuupäev',
      confirmations: 'Kinnitan, et',
      know_buy_material: 'Tean, et võin stuudios materjale osta',
      customer_will_complete_agreement: 'Iga minu klient allkirjastab kliendilepingu',
      can_show_documents: 'Vajadusel saan stuudios originaaldokumente esitada',
      has_country_permission: 'Mul on riigis viibimise luba',
      has_san_book: 'Mul on sanitaaraamat',
      has_passport: 'Mul on isikut tõendav dokument (ID)',
      conditions: 'Olen tutvunud renditingimustega',
    },
  },
  user: {
    referral_links: {
      booking: 'Seansi broneerimine',
      student_candidate: 'Koha rendimine',
      store: 'Kliendipood',
      candidates_form: 'Kutsu töötaja',
    },
  },
  products: {
    want_add: 'Soovin lisada valitud hooldustooted seansi hinnale',
    no_warranty: '*selle valiku puudumisel ei saa me tagada teile 100% edukat paranemist',
    button_add: 'Lisa',
  },
  healing_questionary: {
    composable_table: {
      heading: 'Kasutatud tarvikud',
      name: 'Nimi',
      count: 'Kogus',
    },
  },
  booking: {
    fast_booking: 'Lihtsustatud broneerimine',
    develop_sketch: 'Visandi arendamine',
    fast_booking_warning:
      'Broneering ei ole lõplik, meie haldur võtab teiega ühendust, et kinnitada sessiooni aega ja kuupäeva',
    phone_annotate:
      'Selleks, et meie müügimeeskond saaks kiiremini vastata, palun andke oma kohalik kontakttelefoninumber. Aitäh!',
    increased_risk: 'Tähelepanu! Kõrge riskiga seanss',
    increased_risk_extended: 'Valitud sessiooni tüüp ja asukoht kehal on suurenenud riskiga',
    price_warning: 'Lisatooted ei sisaldu hinnas',
    approximate_price: 'Ligikaudne hind',
    type: {
      info: 'Mugav broneerimisvorm kogu vajaliku teabega',
      booking_button: 'BRONEERI SEANSS',
      booking_button_promo: '15% TAGASTAMISEGA VEAN COINSIS',
      unsure: 'Kas pole veel valikut teinud? Me aitame!',
      consultant_help: 'Meie konsultandid aitavad teid hea meelega',
      consultation_button: 'KONSULTATSIOON',
    },
    color: {
      black: 'Must',
      colored: 'Värviline',
    },
    controls: {
      next: 'EDASI',
      back: 'Tagasi',
      no_select: 'Pole valitud',
    },
    place_service: {
      select_studio: 'Valige stuudio',
      service: 'Teenindus',
      size: 'Suurus',
      location: 'Asukoht',
    },
    customer_info: {
      login_prompt: 'Jätkamiseks logige oma kontole sisse',
      login_button: 'Logi sisse',
      black_white_colored: 'Must ja valge või värviline?',
      select_style: 'Valige stiil',
      tell_more:
        'Rääkige meile rohkem oma tätoveeringu soovidest ja jagage meiega tätoveeringu näiteid (viited)',
      login_promo: 'Sisselogimisel Cashback 10%',
      wish: 'Soov',
    },
    date_time: {
      choose_time: 'Valige külastuse aeg',
    },
    master_card: {
      master_unavailable: 'Meister pole sellel ajal saadaval',
      master_guest_warning: 'TÄHELEPANU! Külalismeister',
      rank: 'Aste:',
      works_count: 'Teoste arv:',
      book_now: 'Broneeri kohe',
      select_another_time: 'Vali teine aeg',
    },
    success: {
      session: 'SEANSS',
      become_mystery_shopper: 'Muuda end salajaseks ostjaks',
      add_to_calendar: 'Lisa sündmus kalendrisse',
      call_on_day: '*seansi päeval helistab teile salongi administraator',
      return_to_menu: 'Tagasi menüüsse',
      recommended_products: 'Soovitatud tooted paranemiseks',
    },
    black_friday: "50% allahindlus kohustusliku sissemaksega",
  },
  tips: {
    placeholder: 'Valige, kuidas jootraha jagada',
    divide_by_all: 'Võrdselt',
    administrator: 'Administraatorile',
    parlor: 'Salongile',
    master: 'Meistrile',
  },
  mystery_shopper: {
    title: 'Salajane ostja',
    description:
      'Salajane ostja on inimene, kes kontrollib salongi tööd tavalise kliendi näol. Ta hindab salongi teenindustaset.',
    offer: 'Kui otsustate selle seansi jaoks salajaseks ostjaks saada, kingime teile VeanCoins',
    instructionTitle: 'Juhend',
    instructionDetails:
      'Seansi ajal peate salongi ja personali hindama erinevate kriteeriumide järgi. Pärast seanssi saate SMS-i koos küsitluse lingiga, mille peate täitma (ainult ausalt!)',
    question: 'Kas soovite saada salajaseks ostjaks?',
    yesButton: 'Jah',
    noButton: 'Ei, aitäh',
    successMessage: 'Peate hindama stuudiot järgmiste parameetrite järgi:',
    criteria1: 'Telefonikonsultatsioon ja sotsiaalmeedia',
    criteria2: 'Konsultatsioon stuudios saabumisel',
    criteria3: 'Stuudio hinnapoliitika',
    criteria4: 'Eksterjöör: stuudio fassaad, sildid, asukoht jne.',
    criteria5: 'Stuudio interjöör, sisustus',
    criteria6: 'Toodete, suveniiride ja paranemistoodete valik (vitrine)',
    criteria7: 'Õhkkond: TV, muusika, kollektiiv',
    criteria8: 'Võõrustamine, ajaviide, ajakirjad ja visandikataloogid',
    criteria9: 'Suhtlemine meistriga',
    criteria10: 'Suhtlemine administraatoriga',
  },
  cookies: {
    text: `Kasutame küpsiseid, et parandada teie sirvimist,
      näidata isikupärastatud reklaame või sisu,
      ning analüüsida meie liiklust. Vajutades "Nõustu",
      nõustute küpsiste kasutamisega.`,
    confirm: 'Nõustu',
    more: 'Rohkem infot',
    manage: 'Halda',
    config: {
      necessary: 'Vajalikud',
      preferences: 'Eelistused',
      statistics: 'Statistika',
      marketing: 'Turundus',
    },
  },
  header_menu: {
    logout: 'Logi välja',
    main: 'Avaleht',
    about_us: 'Meist',
    discount_page: 'Kampaaniad',
    loyalty_page: 'Loyalty süsteem',
    ai_page_link: 'Loo visand',
    fonts_page_link: 'Tätoveeringu fondid',
    quality_certificate: 'Kvaliteedisertifikaadid',
    complaints: 'Kaebused ja ettepanekud',
    public_offer_link: 'Avalik pakkumine',
    confidence: 'Privaatsuspoliitika',
    work: 'Tahan töötada VEAN-is',
    dockuments_page_link: 'Dokumendid',
    login: 'Logi sisse',
    account: 'Minu konto',
    studying: 'Ma tahan õppida!',
    buy_certificate: 'Osta sertifikaat',
    feedback: 'Tagasiside', 
  },
  post: {
    welcome: 'Tere. Te olite meie juures seansil',
    no_answer_questions: 'Te ei vastanud järgmistele küsimustele:',
    point: 'Palun hinnake teenuse kvaliteeti ja saate kingituse!',
    send: 'Saada',
    error: 'Tekkis viga!',
    not_found: 'Seanssi ei leitud!',
    not_call: 'Mul ei ole vaja tagasi helistada',
    thank: 'Suur aitäh hinnangu eest!',
    bot: '21 päeva pärast helistab teile tundmatu number, et täpsustada teavet teenuse ja paranemise kohta.',
    certificate: 'Teile on genereeritud aktsioonissertifikaat %{gift_price} VeanCoins-i eest',
    social_networks: 'Palun jätke meile arvustus sotsiaalvõrgustikes:',
    month: 'Kasutage seda kuu jooksul, ärge unustage administraatorile sellest teatada!',
    dissatisfied:
      'Kas te ei ole pakutava teenusega rahul? Me töötleme teie taotlust nii kiiresti kui võimalik.',
    scores: {
      administrator: 'Minu hinnang stuudio administraatorile:',
      master: 'Minu hinnang stuudio meistrile:',
      recommendations: 'Kas mulle anti suulised ja kirjalikud soovitused hoolduseks:',
      care: 'Kas mulle pakuti professionaalseid hooldusvahendeid:',
      sketch: 'Kas mulle pakuti individuaalset visandi/projekti tellimist:',
      souvenir: 'Kas mulle pakuti suveniirtooteid:',
      master_contact:
        'Kas meister jättis teile oma isiklikud kontaktandmed (telefoninumber, link isiklikele sotsiaalvõrgustikele) või kas te andsite meistrile oma kontaktid?:',
      repeat_record: 'Kas soovite uuesti broneerida?',
    },
    scores_mystery_shopper: {
      instagram: 'Meie INSTAGRAM-i hinnang',
      site: 'vean-tattoo.%{locale} veebisaidi hinnang',
      phone_consultation: 'Telefonikonsultatsioon ja sotsiaalmeedia',
      consultation: 'Konsultatsioon stuudios saabumisel',
      price: 'Stuudio hinnapoliitika teie arvates',
      exterior: 'Eksterjöör: stuudio fassaad, sildid, asukoht jne.',
      interior: 'Stuudio interjöör, sisustus',
      assortment: 'Toodete, suveniiride ja paranemistoodete valik (vitrine)',
      atmosphere: 'Õhkkond: TV, muusika, kollektiiv',
      leisure: 'Võõrustamine, ajaviide, ajakirjad ja visandikataloogid',
      master: 'Suhtlemine meistriga',
      admin: 'Suhtlemine administraatoriga',
      master_one_place: 'Kas meister töötab ainult selles stuudios? (jah/ei)',
      master_contact: 'Kas meister andis oma isiklikud kontaktandmed? (jah/ei)',
      care: 'Kas teile pakuti osta paranemisvahendeid ja meie suveniirtooteid, kingiti väike kingitus: magnet, kleebis või 10% sertifikaat järgmiseks teenuseks? (jah/ei)',
      conclusion: 'Lõplik hinnang kontrollile',
    },
    inputs: {
      cost: 'Maksin töö eest:',
      cost_other: 'Lisaks ostsin kaunistusi/hooldusvahendeid summas:',
      review: 'Meie puudused, mis ilmnesid töö ajal:',
      photo: 'Stuudio foto',
    },
    inputs_mystery_shopper: {
      recommendation: 'Teie soovitused meile',
      why_vean: 'Miks valisite VEAN?',
    },
  },
  header: {
    online_appointment: 'Online broneerimine',
    post_session: 'Teenuse kvaliteedi hinnang',
    get_price: 'Konsultatsioon',
    order_consultation: 'Telli konsultatsioon',
    consultation: '(Konsultatsioon)',
  },
  index: {
    parlor: 'Stuudio',
    master: 'Meister',
    session_type: 'Teenuse tüüp',
    session_location: 'Teenuse asukoht',
    datetime: 'Kuupäev ja kellaaeg',
    create_appointment: 'Broneeri',
    bonus: '* BOONUS %{customer_bonus} VeanCoins ISESEISVAKS BRONEERIMISEKS',
    sketch: 'Visand (pole kohustuslik)',
  },
  session_types: {
    tattoo: 'Tätoveering',
    tatouage: 'Püsimeik',
    piercing: 'Augustamine',
    stroke: 'Eemaldamine',
    training_tattoo: 'Tätoveerimise koolitus',
    training_piercing: 'Augustamise koolitus',
    training_tattouage: 'Püsimeigi koolitus',
    training_udder: 'Eemaldamise koolitus',
  },
  price_form: {
    client_name: 'Nimi',
    client_phone: 'Telefon',
    client_comm: 'Teie teenus',
    sketch_desc: 'Lisa visand (* Ei ole kohustuslik)',
    sketch_button: 'Vali fail',
    send: 'Saada',
    or: 'Või',
    country: 'Riik',
    city: 'Linn',
    comment: 'Kommentaar',
    val_error: 'Kontrollige sisestatud andmeid!',
    ths_message: 'Aitäh! Võtame teiega varsti ühendust',
    go_back: 'Tagasi',
    file_size: 'Faili suurus ei tohi ületada 5 MB!',
  },
  selectors: {
    parlor: {
      placeholder: 'Otsing salongi nime järgi...',
    },
    master: {
      placeholder: 'Otsing nime, teenuse järgi…',
    },
    select_type: {
      select: 'Vali',
      next: 'Edasi',
      no_price: 'Kokkuleppeline',
      ad: 'alates',
      da: 'kuni',
      selected: 'Valitud',
      info: 'Hind võib sõltuda suurusest ja asukohast',
    },
    datetime: {
      select_date: 'Valige soovitud kuupäev',
      no_active_times: 'Sellel kuupäeval pole enam vabu aegu. Valige teine, palun!',
    },
  },
  verify: {
    verify_account: 'Kontrollige kontot',
    type: 'Kinnitamise viis',
    check_code: 'Koodi kontroll',
    status: 'Staatus',
    input_phone: 'Sisestage telefoninumber',
    email_help: 'Kinnitamiseks võite kasutada ka e-posti',
    sms_sent: 'Teile on saadetud SMS-kood, sisestage see allpool',
    resend_sms_after: 'Koodi saab uuesti saata %{seconds} sekundi pärast',
    resend_sms: 'Saada uuesti',
    buttons: {
      back: 'Tagasi',
      next: 'Edasi',
      create_appointment: 'Broneeri',
    },
  },
  success: {
    h1: 'Aitäh! Broneering on edukalt loodud!',
    h2: 'Pärast broneeringu kinnitamist administraatori poolt saadetakse teile SMS.',
    mystery: 'Muuda end salajaseks ostjaks',
  },
  about: {
    header: 'Teave teie kohta',
    birth_info:
      'Sünnikuupäeva märkimine võimaldab meil veenduda teie vanuses ja see on vajalik sünnipäeva allahindluse saamiseks :)',
    inputs: {
      name: 'Ees- ja perekonnanimi',
      find_out: 'Kust te meid leidsite',
      birth_date: 'Sünnikuupäev',
    },
    buttons: {
      skip: 'Jäta vahele',
      back: 'Tagasi',
      next: 'Edasi',
    },
  },
  footer: {
    header: 'Liituge meie sotsiaalvõrgustikega, et olla kursis kõigi uudistega!',
    site_navigation: 'Navigatsioon',
    interest: 'Huvitav',
    other: 'Muu',
  },
  subscribe_modal: {
    header: 'Telli uudiseid ja saa boonus',
    submit: 'Saada',
    wrong_data: 'Kontrollige sisestatud andmete õigsust!',
    wrong_email: 'Vigane e-posti aadress!',
    thanks: 'Tänan tellimise eest!',
    error: 'Tekkis viga!',
  },
  chat_bot: {
    call: 'Helista',
    online_record: 'Online broneerimine',
    online_consultation: 'Online konsultatsioon',
    need_help: 'Kas vajate abi visandiga?',
    order_sketch: 'Telli visand',
    dress_sketch: 'Proovi visandit',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Probleemsed olukorrad',
      id: 'Number',
      status: 'Staatus',
    },
    reset_password: {
      title: 'Unustasid parooli',
      submit: 'saada',
      forgot_password: 'Unustasid parooli',
      phone: 'Sisestage telefoninumber',
      success_message: 'Teie e-posti/telefonile saadeti ajutine parool',
    },
    registration: {
      name: 'Nimi',
      surname: 'Perekonnanimi',
      registered: 'Registreerimine',
      congratulations: 'Õnnitleme!',
      successfully_registered: 'Olete edukalt registreerunud!',
      log_in: 'Logi sisse',
    },
    options: {
      no_interest_in_promotions: 'Mind ei huvita kampaaniad ja boonused',
      dislike_frequent_sms: 'Mulle ei meeldi sagedased SMS-sõnumid',
      already_have_tattoo: 'Mul on juba tätoveering ja ma ei plaani lähitulevikus uut teha',
      other: 'Muu',
    },
    login_page: {
      log_in_page: 'Logi sisse oma kontole',
      number_missing: 'Teie numbrit pole?',
      register: 'Registreeru!',
      steps: {
        sign_up: 'Registreeruge lojaalsusprogrammi või LOGI SISSE',
        check_coins: 'Kontrollige oma VeanCoin-i, registreerudes lojaalsusprogrammi',
        get_coins: 'Saage oma VeanCoin iga sessiooni lõpus',
        accumulate: 'Koguge ja koguge oma profiilis VeanCoin',
        book_session: 'Broneerige sessioon ja tulge Veanisse oma tätoveeringut saama',
        exchange_coins: 'Vahetage VeanCoin-id ja saage soodustusi',
      },
    },
    find_customer: {
      continue: 'Jätka',
      select_type: 'Valige sisselogimise tüüp',
      no_password: 'Te ei ole parooli määranud!',
      not_found: 'Klienti ei leitud!',
      found: 'Leitud!',
      check: 'Kontrollimine...',
      invalid_phone_or_password: 'Vigased sisselogimisandmed',
      error_login: 'Sisselogimise viga',
      phone: 'Telefon',
      temporary_password_message: 'Kasutate ajutist parooli',
      permanent_password_message: "Kasutate püsivat parooli",
    },
  },
  product_card: {
    availability: 'Saadavus stuudiotes:',
    unavailable: 'Pole saadaval',
  },
  store_body: {
    full_points_products: 'Tooted, mida saab osta VeanCoins eest',
    half_points_products: 'Tooted, mida saab osta 50% allahindlusega',
    no_products: 'Tooteid pole',
  },
  description_block: {
    loyalty_system: 'VEAN lojaalsussüsteem',
    show_more: 'Näita rohkem',
  },
  profile_card: {
    my_sketches: 'Minu visandid',
    share_link: 'Jaga seda linki',
    tips: 'Jootraha',
    send_certificate: 'Saada sertifikaat',
    vip: 'VIP',
    not_vip: 'Muuda VIP-ks',
    veancoins: 'VeanCoins',
    balance_buy: 'Raha',
    active_certificates: 'Aktiivsed VeanCoins',
    balance: 'Saldo',
    sessions: 'Seansid',
    points_alltime: 'VeanCoins teenitud kogu aeg',
    available_certificates: 'Saadaolevad sertifikaadid',
    certificates: 'Rahasertifikaadid',
    bought_certificates: 'Aktiivsed VeanCoins',
    certificate_number: 'Sertifikaadi number',
    available_for: 'Kehtib kuni:',
    no_active_certificates: 'Rahasertifikaate pole',
    no_active_bought_certificates: 'Aktiivseid VeanCoins pole',
    coming_sessions: 'Eesolevad seansid',
    complete_agreement: 'Täitke leping',
    date: 'Kuupäev:',
    you_was: 'Olete olnud %{sessions} seansil',
    edit: 'Redigeerimine',
    not_email: 'Sertifikaadi saatmiseks peate täitma e-posti aadressi',
    exist_application: 'On olemas rakendus',
    need_exist_application: 'Rakendus puudub',
    need_confirm_account: 'Konto kinnitamine on vajalik',
    verified: 'Kinnitatud',
    choose_payment_method: 'Valige makseviis',
    parlor: 'Salong',
    sign_up_online: 'Broneeri seanss',
    mystery_shopper: 'Muuda end salajaseks ostjaks',
    sign_up_secret_buyer: 'Broneerige seanss ja muutuge salajaseks ostjaks',
    pay: 'Maksma',
    prepayment: 'Ettemaks',
    questionnaire: 'Küsimustik',
    agreement: 'Leping',
    fill_required_information: 'Täitke vajalik teave',
    buy: 'Täiendage saldo',
    archive: 'Arhiiv',
    support_service: 'Vean Tattoo Community tugi',
    balance_buy_history: 'Ostetud VeanCoins ajalugu',
    balance_history: 'Teenitud VeanCoins ajalugu',
    buy_history: 'Ostude ajalugu',
    сost: 'Maksumus',
    payment_link: 'Makse link',
    attributes: 'Atribuudid',
    invite_friend: 'Kutsu sõber',
    phone_or_email: 'Sisestage telefoninumber või e-post',
    report_problem: 'Esita kaebus',
    note: 'Sisestage kommentaar',
    verify_account_status: 'Konto staatus',
    security_percent: 'Turvalisus:',
    tooltip_coins:
      'Peate aktiveerima oma kogutud VeanCoins, et vältida nende kehtivuse lõppemist 1 aasta jooksul',
    tooltip_certificate:
      'Teie ostetud või kingitud rahasertifikaatide arv, mida saab kasutada 2 kuu jooksul',
    tooltip_сonfirmed: 'Redigeerimisel muutke "FIO või kasutajanimi" "Nimeks ja perekonnanimeks"',
    tooltip_missing: 'Lisage teave enda kohta (nimi ja perekonnanimi, sünnikuupäev, link teie sotsiaalvõrgustikele, kust te meid leidsid, telefoninumber ja e-post isiku tuvastamiseks)',
    tooltip_verified: 'Peate kinnitama oma konto telefoni või e-posti kaudu, et kaitsta teie teenitud ja kogutud VeanCoins.',
    tooltip_vip: 'VIP klient on staatus, mille saab iga klient, kes on kulutanud teenustele üle 1500 euro. VIP staatus annab teatud eelised, nagu kiire prioriteetne seanssibroneering, juurdepääs ainulaadsetele toodetele ja teenustele ning pärast VIP staatuse saavutamist saab klient kohe oma kontole 1000 VeanCoins.',
    earn_veancoins: 'Teeni veancoins',
  },
  profile_edit: {
    additional_information: 'Täiendav teave VeanCoins eest',
    parlors: 'Salong (kõige lähem või peamine)',
    client_card: 'Kliendikaart',
    full_name: 'Täisnimi või kasutajanimi',
    birth_date: 'Sünnikuupäev (õnnitlemiseks)',
    social_media_link: 'Link sotsiaalvõrgustikule',
    find_out: 'Kust teada said',
    or: 'või',
    phone_placeholder: 'Telefon',
    save: 'Salvesta',
    email_taken: 'See e-post on juba kasutusel!',
    phone_taken: 'See telefoninumber on juba kasutusel!',
    simple_password: 'Liiga lihtne parool!',
    invalid_password: 'Vale parool!',
    password_for_application: 'Rakenduse parool (pole kohustuslik)',
    congratulate: 'Õnnitleme!',
    successfully_registered: 'Olete edukalt registreerunud!',
    need_confirm_account: 'Konto kinnitamine on vajalik',
    yes: 'Jah',
    phone_confirmed: 'Telefon kinnitatud',
    email_confirmed: 'E-post kinnitatud',
    change_password: 'Muuda parooli',
    old_password: 'Vana parool',
    new_password: 'Uus parool',
    confirm_password: 'Kinnita parool',
    invalid_phone_or_password: 'Vale telefoninumber või parool',
    error_login: 'Sisselogimisel tekkis viga',
    years_old: 'Vanus',
    up_18_years_old: '16-21',
    for18_24: '21-27',
    for25: '27-35',
    for35_45: '35-45',
    up_45_years_old: '45+',
    gender: 'Sugu',
    man: 'Mees',
    woman: 'Naine',
    gender_hidden: 'Eelistan mitte öelda',
    language: 'Keel',
    preferred_style_status: 'Eelistatud stiilid',
    classic: 'Klassika / realism',
    freak: 'Hullus / Prügi / Anime',
    fashion: 'Mood / Kunst / Minimalism',
    rock: 'Rock / Kõva',
    pop: 'Pop / Mass',
    accommodation_status: 'Elukoht',
    local: 'Kohalik',
    tourist: 'Turist',
    guest: 'Külaline',
    lifestyle_status: 'Elustiil',
    extreme: 'Ekstreemne',
    dance: 'Tants',
    anime: 'Anime',
    game: 'Mäng',
    music: 'Muusika',
    lgbt: 'LGBT',
    art: 'Kunst / Kaasaegne',
    family: 'Perekond / armastus',
    beauty: 'Ilu',
    sexuality: 'Seksuaalsus',
    masculinity: 'Mehisus',
    tattoo_count_status: 'Tätoveeringute arv',
    pierce_count_status: 'Augustuste arv',
    no: 'Ei',
    one: 'Üks',
    two: 'Kaks või rohkem',
    inc: 'Väga palju',
    change_information: 'Salvesta teave',
  },
  verification: {
    sms: 'Kinnita SMS-iga',
    email: 'Kinnita e-postiga',
    google: 'Kinnita Google kaudu',
    send_code: 'Saada kood',
    code: 'Kinnituskood',
  },
  loyalty: {
    submit_button: 'Saada',
    bonus_instruction:
      'Tehke üks loetletud toimingutest, lisage tõendid (ekraanipildid, fotod jne) ja saate boonusena VeanCoins!',
    bonus_amount: 'VeanCoins!',
    review_option_1: 'Jätke meistri profiilile põhjalik ülevaade veebisaidil',
    review_option_2: 'Hinnake stuudiot või meistrit veebisaidil',
    review_option_3: 'Hinnake stuudiot ja jätke arvustus kaardile',
    review_option_4: 'Jagage meie stuudio postitust sotsiaalvõrgustikes',
    return:
      'Kui teie saadetud foto ei vasta ühelegi boonuspunkti kategooriale, siis annulleeritakse saadud boonused sertifikaadi ostmisel.',
    evidence_label: 'Tõendid',
  },
  buy_certificate: {
    payment_type_title: 'Ostu tüüp',
    buy_balance: 'Saldo täiendamine',
    buy_certificate: 'Sertifikaadi ostmine',
    payment_type_subtitle: 'Mida soovite teha',
    amount_title: 'Summa',
    amount_subtitle: 'Sisestage summa',
    payment_method_title: 'Makseviis',
    payment_method_subtitle: 'Valige makseviis',
    payment_title: 'Makse',
    forming_payment: 'Makse moodustamine...',
    pay: 'Maksma',
    continue: 'Jätka',
    back: 'Tagasi',
    submit_to_email: "Saada sertifikaat e-postiga",
    get_vean_card: "Hankige VEAN kaart stuudios",
    vean_card_description: "VEAN CARD on teie kaart, kus VEAN TATTOO sertifikaadid aktiveeritakse automaatselt, tagades turvalisuse ja mugavuse. Kõik teie kingitused ja allahindlused on alati käepärast ning kaardi kasutamine on võimalikult lihtne ja kasulik. Hankige oma kaart VEAN TATTOO stuudiotes ja avastage kõik eelised juba täna!",
  },
  parlor_page: {
    header: {
      parlors_count: 'Üle 150 salongi',
      clients_count: 'Üle 1 000 000 kliendi',
      largest_company: 'Suurim tätoveerimissalongide võrgustik maailmas',
    },
    master_block: {
      our_masters: 'Meie meistrid',
    },
    master_card: {
      works_count: 'Teoste arv: ',
    },
    no_admin_block: {
      leisure: 'Ajaviide',
      session_record: 'Seansi broneerimine',
      about_company: 'Ettevõttest',
      care: 'Hooldus',
      quality_certificates: 'Kvaliteedisertifikaadid',
      public_buyout_offer: 'Avalik pakkumine',
      complaints_offers: 'Kaebused ja ettepanekud',
      loyalty_system: 'Loyalty süsteem',
      discount_page: 'Allahindlused',
    },
    peculiarities_block: {
      about_studio: 'Stuudiost',
      clients: 'Kliendid',
      sessions: 'Seansid',
      studio_hours: 'Tööaeg: ',
      studio_phone: 'Stuudio number: ',
      wifi: 'Wi-fi: ',
      password: 'Parool: ',
      from_to: 'kell %{time_start} kuni %{time_end}',
    },
    photos_block: {
      photos: 'Stuudio fotod',
    },
    top_works: 'Parimad tööd',
    care_important: 'Kõige olulisem tätoveerimisel ja augustamisel on paranemine ja hooldus.',
    cabinet: 'Minu kabinet',
    complete_agreemnent: 'Check-in',
    is_blanket: 'Saadaval on tekk, millega saate end soojendada',
    is_water: 'Saadaval on kuumad joogid',
    is_hot_drinks: 'Saadaval on maiustused',
    is_treats: 'Saadaval on külmad joogid',
  },
  promotion: {
    submit: 'Saada',
    warning: 'Tähelepanu! Kontrollige sisestatud andmete õigsust!',
    name: 'Nimi / Hüüdnimi',
    required: 'See väli on kohustuslik!',
    max_value: 'Peab olema kuni 100 tähemärki!',
    phone: 'Telefon',
    social_link: 'Link sotsiaalvõrgustikule',
    wrong_format: 'Vigane formaat!',
    terms: 'Olen tutvunud isikuandmete töötlemise poliitikaga',
    know_bonus: 'Ma tean, et mulle antakse boonuspunktid',
    error: 'Tekkis viga',
    thanks: 'Aitäh!',
    bonus: 'Teile on antud %{gift_price} punkti',
    do_not_disturb: 'Ärge segage (lülitage välja SMS ja kõned VeanTattoo-st)',
    first_time_veancoins_notification:
      'VeanCoins-i esmakordseks kasutamiseks peate pöörduma stuudio administraatori poole. VeanCoins ja Raha järgivad erinevaid kasutusreegleid',
    get_promo: 'Soovin saada teateid allahindluste ja kampaaniate kohta',
  },
  another_studio_drawler: {
    another: 'Teised stuudiod',
  },
  landing: {
    best_studio: 'Parim tätoveerimissalong teie linnas',
    vean_wide: 'VEAN TATTOO VÕRGUSTIK',
    studio_count: 'Üle 150 tätoveerimissalongi',
    quality: 'Kvaliteedi ja turvalisuse garantii',
    contact_us: 'Võtke meiega ühendust',
    get_price: 'HINNAKÜSITLUS', //võib-olla on olemas
    advantage: 'Eelised',
    top_works: 'Parimad tööd',
    prices: 'Hinnad', //peab olema
    studio_photo: 'Stuudio fotod',
    prices_services: 'Hinnad ja teenused',
    about: 'Meist',
    loyalty_system: 'Loyalty programm', //peab olema
    why_vean: 'Miks VEAN',
    visit_count: 'Inimesed on seda stuudiot külastanud',
    description:
      'Vean Tattoo stuudiote võrgustik on praegu maailma suurim: meil on üle 150 salongi 14 Euroopa riigis. Oleme viinud selle valdkonna täiesti uuele, kõrgemale tasemele. Võrgustiku spetsialiseerumine on üsna lai, kuid põhisuund on tätoveerimis- ja augustustööstus. Vean Tattoo ettevõtte põhiväärtused: Ekspertiis ja kättesaadavus: Püüame tagada, et iga klient tunneks end protsessi igal etapil mugavalt, seetõttu on meie spetsialistid valmis vastama kõigile teie küsimustele veebis. Reputatsioon ja kliendikesksus: Iga klient on võimalus luua nende nahale ainulaadne kunstiteos. Hoolime teie ajast ja püüame, et te lahkuks meie stuudiost paremas tujus ja suurepärase tätoveeringuga. Hoolime oma reputatsioonist ja väärtustame iga võrgustiku klienti. Väärtus ja kättesaadavus: Pakume ideaalset hinna ja kvaliteedi suhet. Meie teenused on laiale publikule kättesaadavad, ohverdamata seejuures kvaliteeti. Sertifitseeritud ja kvaliteetsed seadmed: Meie materjalid vastavad kõrgeimatele turvalisuse ja kvaliteedi standarditele. Garanteerime, et meie kliendid saavad ainult parimat. Kõrgelt kvalifitseeritud spetsialistid ja lai teenuste valik: Vean Tattoo stuudiotes töötavad ainult diplomiga meistrid, keda hinnatakse ja austatakse kogu maailmas. Pakume kõiki olemasolevaid tätoveerimisstiile ja -tehnikaid ning tasuta individuaalse visandi loomist teie nõudmiste järgi. Samuti soovitame teil tellida kõik meie ametlikud sotsiaalvõrgustike lehed. See võimaldab teil olla kursis eripakkumiste, kampaaniate, konkursside, meistrite tööde, viimaste uudiste ja Vean Tattoo ettevõtte uuendustega.',
    advantages: {},
    our_masters: 'Meie meistrid', //peab olema
    from: 'alates',
    no_price: 'Kokkuleppeline',
    form: {
      get_price_consultation: 'Uuri hinda / Konsultatsioon',
      name: 'Nimi',
      phone: 'Telefon',
      place: 'Teie asukoht',
      subscribe_news: 'Telli uudised ja saa boonus',
    },
  },
  form_messages: {
    submit: 'Saada',
    warning: 'Tähelepanu! Kontrollige sisestatud andmete õigsust!',
    required: 'See väli on kohustuslik!',
    length: 'Peab olema vähemalt %{length} tähemärki!',
    file_length: 'Peab olema kuni %{length} faili!',
    wrong_format: 'Vigane formaat!',
    error: 'Tekkis viga',
    thanks: 'Aitäh!',
    required_label: 'Kohustuslik',
    not_required_label: 'Valikuline',
  },

  training: {
    training: 'KOOLITUS',
    tattoo_school: 'TATTOO KOOL',
    biggest_school: 'maailma suurim tätoveerimissalongide võrgustik',
    ne_otkladivaj: 'Ära<br/>jäta<br/>ennast hilisemaks',
    learn: 'Õpi tegema tätoveeringuid<br/>ja saa tipptasemel meistriks',
    reviews: 'Lõpetajate arvustused',
    record: 'Registreeri',
    video_text: 'Mida aitame,<br/>mida õpetame?',
    ded: {
      consultation: 'konsultatsioon',
      get_more: 'Loe rohkem teavet',
    },

    header_bar: {
      main: 'Avaleht',
      get: 'Mida saate',
      program: 'Kursuse programm',
      about: 'Meist',
      reviews: 'Arvustused',
    },

    our_course: {
      head: 'Meie<br/>kursused',
      description_1: 'see on koht, kus saate alati vastuse küsimusele ja õpite midagi uut',
      description_2: 'Ja isegi kui teil pole kunstikooli tausta - tulemus ei lase end kaua oodata',
      label: '[ koos meiega on soe, hubane ja lõbus ]',
      description_3:
        'Me armastame oma õpilasi ja püüame teha kõik, et õppeprotsess oleks võimalikult mugav',
    },

    banner: {
      we_teach: 'Õpetame Sind Tätoveerima',
      good_make: 'TEEME KÕIGE KÕVEMAID TÄTOVEERIMISI',
      our_masters: 'MEIE MEISTRID ON VALMIS JAGAMA TEADMISI, SALADUSI JA TEHNIKAID',
      learn_too: 'Ja õpetame ka sind',
    },
    modal: {
      thanks: 'Aitäh!',
      error: 'Tekkis viga!',
      complete: 'Täitke vorm, võtame teiega ühendust',
      full_name: 'Ees- ja perekonnanimi',
      phone: 'Telefoninumber',
      submit: 'Saada',
      cancel: 'Tühista',
    },

    paragraph: {
      header_1: 'Kindlasti õpetame',
      content_1: 'Me ei tee õppimist konveiermeetodil',
      header_2: 'oskama joonistada',
      content_2: 'Õpetame kõike ise / pole kunstiharidust, pole üldse probleem',
      header_3: 'Sertifikaat',
      content_3:
        'Kursuste lõppedes saate rahvusvahelise standardi sertifikaadi. Kinnitatud Global Tattoo ACC poolt',
      header_4: 'Töövõimalus',
      content_4:
        'OMG, kas te tõesti räägite tõsiselt? Jah, parimad lõpetajad võivad saada osa meie meeskonnast ja töötada kõikjal Euroopas',
      header_5: 'Leping',
      content_5: 'Me ei tööta "ausa sõna" alusel. Kõik on lepinguga ja ametlikult',
      no_sleep: {
        header: 'ära prospatu',
        rect_1: 'Kuidas?',
        rect_2: 'ELU',
        main_label: 'Alusta õppimist kohe',
        label_1: '1000+ lõpetajat',
        label_2: '100% jõudsid õppimise lõpuni',
        label_3: '80% leidsid töö',
        label_4: '300+ partnerit tätoveerimistööstuses',
      },
    },
    principies: {
      head: 'Garantii',
      label_1: '1. Kultuur',
      label_2: '2. Kvaliteet',
      label_3: '3. Meetodid',
      heading: 'Kõik põhineb põhimõtetel',
      description_1:
        '11 aastat tagasi otsustasime hakata arendama tätoveerimistööstuse kultuuri, mitte nagu kõik teised, oma viisil - tuginedes teadmistele ja kogemustele',
      description_2:
        'Täna oleme koolitanud üle 1000 suurepärase tätoveerimismeistri. 80% neist on saanud osa meie meeskonnast. Võime kindlalt öelda, et tätoveerimiskunsti õppida võib igaüks',
      description_3:
        'Kõik on meetodis, mida kasutame, ja mida pidevalt täiustame koos meeskonna, kunstnike ja meie lõpetajatega, kes annavad regulaarselt tagasisidet',
    },
    packages: {
      head: 'Kursuste programmid',
      from: 'alates',
      plus: 'Plussid',
    },
    facilities: {
      head: 'Võimalused ja eelised',
      subhead: 'Valige meie kursused ja koguge tätoveerimistööstuse maagia',
      card: {
        head_1: 'Iga meie lõpetaja saab rahvusvahelise standardi sertifikaadi',
        label_1: '- teie pilet võimaluste maailma',
        head_2: 'Soovitused ja toetus professionaalsete meistrite grupilt',
        label_2: 'Teie edu on meie uhkus!',
        head_3: 'Tätoveerimiskogukond, saage nõuandeid ja toetust meie ekspertidelt',
        label_3: 'Teie uus elu tätoveerimismaailmas algab kohe!',
      },
    },
    review: {
      head_1: 'Meistrite juhtumid',
      head_2: 'Arvustused',
      comment_1:
        'Selle kooli tätoveerimise õpe ületas kõik minu ootused! Õpetajad on oma ala professionaalid, iga õppeetapp on põhjalikult läbi töötatud ja atmosfäär on suurepärane. Nüüd saan enesekindlalt oma loomingulisi ideid kliendi nahale viia!',
      comment_2:
        'Tänan kooli kvaliteetse tätoveerimise õppe eest! Algul oli natuke hirmutav sellega tegeleda, kuid tänu tähelepanelikele ja kannatlikele õpetajatele tundsin end enesekindlalt. Nüüd saavad minu tööd tunnustust ja imetlust',
      comment_3:
        'See oli uskumatu teekond tätoveerimismaailmas! Kool pakub kõiki vajalikke teadmisi ja oskusi, et saada tõeliseks professionaaliks. Olen tänulik toetuse ja inspiratsiooni eest, mida siin sain',
    },
  },
  questionary: {
    first: {
      heading: 'VEAN TATTOO STUUDIO KLIENDI PROTSEDUURI NÕUSOLEK',
      textbox:
        'Kui stuudio kliendil ei ole veel 18 aastat täis, täidavad nõusoleku vanemad või seaduslikud hooldajad (Briti Columbias, Kanadas, loetakse alaealiseks isikut, kes on alla 19 aasta vanune. Kui olete alla 19, valige teine variant)',
      has_18: 'JAH, olen 18-aastane või vanem',
      no_18: 'EI, olen alla 18-aastane',
    },
    second: {
      warning: 'Tähelepanu! Seansipäeval tuleb tulla koos hooldajatega!',
      parent_data: 'Ühe vanema andmed:',
      child_data: 'Ühe ALAEALISE andmed:',
      child_field_placeholder: 'Poeg, tütar jne.',
      child_field_label: 'Kellele protseduuri tehakse',
      confirm:
        'Olles alaealise seaduslik esindaja, annan oma nõusoleku järgmise protseduuri läbiviimiseks oma lapsele.',
      name: 'Ees- ja perekonnanimi',
      birthdate: 'Sünnikuupäev',
      address: 'Aadress',
      email: 'E-post',
      sex: 'Teie sugu',
      heading: 'PROTSEDUUR, MIDA TEHAKSE',
      man: 'Mees',
      woman: 'Naine',
      other: 'Mitte määratud',
    },
    third: {
      heading: 'KÕIK, MIDA PEATE TEADMA PARANEMISPERIOODI KOHTA:',
    },
    fourth: {
      nothing: 'Mitte midagi loetletutest',
      has_diseases: 'Kas teil on mingeid haigusi?',
      has_contraindications: 'Kas teil on mingeid vastunäidustusi?',
      heading1:
        'KUI TEIL ON MÕNI ALLTOODUD HAIGUS, PEAME SELLEST TEADMA. PALUN MÄRKIGE SOBIVAD VÄLJAD:',
      heading2: 'KUI ON VASTUNÄIDUSTUSED, PALUN TÄPSUSTAGE SOBIVAT TEAVET:',
    },
    fifth: {
      heading: 'PÄRAST PROTSEDUURI ON KEELATUD:',
    },
    sixth: {
      heading: 'KUST TE MEIST TEADSAITE:',
      label:
        'Pärast 21. päeva võib teiega ühendust võtta üks meie töötajatest, et kontrollida teenuse kvaliteeti. Lisaks saadetakse teile pärast seanssi SMS-informatsioon ja võib-olla helistab teile meie automaatne robot. Oma isiklikus kontos saate loobuda helistamis- ja SMS-informatsiooni teenustest.',
    },
    seventh: {
      heading: 'TAOTLUS JA NÕUSOLEK',
    },
    signature: {
      heading:
        'Mina, allakirjutanu (%{name}), nõustun täna protseduuri %{session_type} läbiviimisega oma kehal ja nõustun selles dokumendis sisalduvate tingimustega.',
    },
    next: 'Edasi',
    submit: 'Saada',
    no_responsibility: 'Stuudio ei vastuta selle seansi eest',
    acquaint: 'Olen tutvunud mulle esitatud teabega',
    acquaint_2:
      'Ma saan aru, et Vean Tattoo ei vastuta paranemisprotsessi eest.\n' +
      'Ülalnimetatud tätoveeringu või augustamise korrigeerimise korral on korrigeerimise maksumus 30% teenuse maksumusest.\n' +
      'Kui te ei ole rahul korrigeerimise tulemusega, siis sellisel juhul teenuse eest raha ei tagastata, kuna eespool nimetatud piirkonnad ei ole teenuse osutamiseks soodsad.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Ilma lahenduseta',
      in_check: 'Kontrollimisel',
      problem_solved: 'Probleem lahendatud',
      wait: 'Ootamisel',
      small: 'Väike olukord',
      unanswered_check: 'Kontrollimisel ilma vastuseta',
    },
  },
  master_page: {
    card: {
      active: 'Aktiivne',
      not_active: 'Mitteaktiivne',
      master_since: 'Meister VEAN alates',
      services: 'Teenused',
      styles: 'Stiilid',
      book: 'Broneeri',
    },
    information: {
      top_works: 'Parimad tööd',
      awards_and_diplomas: 'Auhinnad ja diplomid',
    },
  },
  stripe_warning:
    "Tähelepanu! Minimaalne makse summa Stripe'i kasutamisel on 20€. Kui vajate väiksemat summat, võtke stuudioga ühendust makseandmete saamiseks",
  check_in: {
    welcome_message: 'SOOVIME TOREDAT SEANSSi!',
    session_started: 'TEIE SEANSS ON ALANUD',
    next_steps: 'Pärast seansi lõpetamist on teile kättesaadavad muud etapid',
    buy_healing_product: 'SOOVITAME OSTA RAVI TOODET',
    skip: 'Jäta vahele',
    leave_review: 'Palun jätke ülevaade',
    your_impression: 'Millised on teie üldised muljed?',
    add_photo: 'Lisage töö foto, et saada lisaks 1 Vean Coin',
    send: 'Saada',
    payment_success: 'Makse oli edukas',
    get_invoice: 'Kas soovite saada arvet?',
    invite_friend: 'Kutsuge sõber',
    recommend_us: 'Soovitage meid oma sõpradele ja teenige koos nendega',
    thank_employees: 'Samuti soovitame tänada meie töötajaid',
    you_can_tip: 'Kallis Nikita, võite tänada ka meie töötajaid kindla summaga jootraha',
    own_amount: 'Oma summa',
    who_to_tip: 'Kellele tuleks jootraha määrata?',
    payment_type: 'Makse tüüp',
    continue: 'Jätka',
    summary: 'Kokkuvõte',
    healing_agent: 'Raviv aine',
    tips: 'Jootraha',
    session_payment: 'Seansi makse',
    total: 'Kokku',
    waiting: 'Ootamine...',
    available_funds: 'Saadaval olevad vahendid',
    refill_balance: 'Täida saldo',
    go_to_payment: 'Mine maksmisele',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Jootraha',
    prepayment: 'Ettemaks',
    have_questions: 'On küsimusi?',
    title: 'Seansid',
    selectStatus: 'Valige olek',
    selectParlor: 'Valige salong',
    loading: 'Laadimine...',
    fromDate: 'Kuupäevast',
    toDate: 'Kuupäevani',
    search: 'Otsi',
    all: 'Kõik',
    status: {
      100: 'Ootel',
      200: 'Uus',
      300: 'Käimas',
      400: 'Lõpetatud',
      500: 'Tühistatud',
      600: 'Ootab makset',
    },
  },
  master: {
    rank: 'Ametikoht',
    works_count: 'Tööde arv',
    address: 'Aadress',
  },
  modalPhone: {
    title: "Tähelepanu!",
    info: "Lugupeetud klient, teie turvalisuse ja teenuse tõhusa kasutamise tagamiseks on kõigi andmete lisamine kohustuslik. See aitab meil reageerida olulistele küsimustele õigeaegselt ja tagada teie kaitse.",
    reminder: "Tuleta mulle hiljem",
    go_to_settings: "Mine seadistusse",
    roughly_price: "Umbes hind sõltub teie esitatud andmete täpsusest",
    success_message: 'Aitäh!',
  },
  studyingRegistration: {
    become_student: "Saage üliõpilaseks",
    step_1: "Üldandmed",
    step_2: "Isikuandmed",
    step_3: "Õppe üksikasjad",
    city: "Linn",
    city_placeholder: "Sisesta oma linn",
    name: "Eesnimi",
    name_placeholder: "Sisesta oma eesnimi",
    last_name: "Perekonnanimi",
    last_name_placeholder: "Sisesta oma perekonnanimi",
    date_of_birth: "Sünnikuupäev",
    date_of_birth_placeholder: "Sisesta oma sünnikuupäev",
    phone: "Telefoninumber",
    email: "E-post",
    email_placeholder: "Sisesta oma e-post",
    social_media: "Sotsiaalmeedia",
    social_media_placeholder: "Lingid oma profiilile",
    referral_source: "Kuidas sa kuulsid meist?",
    referral_source_placeholder: "Vali allikas",
    study_type: "Õppe tüüp",
    study_type_placeholder: "Vali õppe tüüp",
    start_date: "Eeldatav alguskuupäev",
    start_date_help: "Sisesta eeldatav alguskuupäev",
    hours_number: "Tundide arv",
    practice_number: "Praktikate arv",
    details: "Üksikasjad",
    education_price: 'Koolituse hind',
  },
  studying_main: {
    become_student: "Saada üliõpilaseks!",
    consultation: "Konsultatsioon",
    want_to_study: "Ma tahan õppida!",
    consult_with_our_advisor: "Ei tea, kust alustada? Võta ühendust meie nõustajaga!"
  },
  errorTicket: {
    sidebar_title: "Teata veast",
    main_title: 'Veaticketi loomine',
    basic_info:
      'Ticketit koostades on oluline esitada kogu vajalik teave teie taotluse kiireks ja tõhusaks töötlemiseks. Siin on näidisaruande struktuur, mis võib teid aidata:',
    ticket_title: 'Ticketi pealkiri:',
    short_description: "Probleemi lühike kirjeldus (nt 'Seansside loomise probleemid')",
    first_title: 'Probleemi kirjeldus:',
    detailed_description: 'Detailne kirjeldus:',
    problem_description: 'Kuidas probleem väljendub, mis täpselt toimub ja mis on vea olemus.',
    reproduction_steps: 'Probleemi kordamise sammud:',
    reproduction_steps_description: 'Loetlege sammud, mis viivad probleemi tekkeni.',
    expected_result: 'Oodatav tulemus:',
    expected_result_description: 'Mida ootasite, et saada või näha?',
    actual_result: 'Tegelik tulemus:',
    actual_result_description: 'Mis juhtus oodatava tulemuse asemel?',
    important_data: 'Olulised andmed:',
    links: 'Lingid:',
    links_description: 'Kandke ette probleemsete lehtede või dokumentide lingid.',
    photos_and_screenshots: 'Fotod ja ekraanipildid:',
    photos_and_screenshots_description:
      'Lisage pildid, mis võivad aidata probleemi visualiseerida.',
    session_numbers: 'Seansside numbrid:',
    session_numbers_description: 'Kandke ette seansside numbrid, mille käigus probleem esines.',
    additional_info: 'Lisainformatsioon:',
    additional_info_description: 'Lisage lisainformatsioon, mis võib olla kasulik.',
    priority_and_urgency: 'Prioriteet ja kiireloomulisus:',
    priority_and_urgency_description:
      'Kui probleem mõjutab oluliselt teie tööd, mainige seda siin ja taotlege kõrget prioriteeti.',
    issue_title: 'Pealkiri',
    issue_title_placeholder: 'Sisestage probleemi lühike kirjeldus',
    description: 'Kirjeldus',
    description_placeholder: 'Kirjeldage probleemi üksikasjalikumalt',
    files: 'Failid',
    drag_and_drop: 'Looge fail siia või valige',
    uploaded_files: 'Üles laaditud failid',
    create_button: 'Loo',
    problem_type: 'Probleemi tüüp',
    select_problem_type: 'Vali probleemitüüp',
    technical: 'Tehniline',
    non_technical: 'Mitte-tehniline',
    loading: "Laadimine...",
    main_page: "Mine avalehele",
  },
  multistep: {
    basic_info: "Põhiandmed",
    type: 'Tüüp',
    summary: 'Kokkuvõte',
    details_rent: "Renditingimused",
    confirmations: "Kinnitused",
    open_google: "Ava Google Mapsis", 
  },
  check_in_view: {
    important: 'Oluline',
    log_in_state_greet: 'Logige oma kontole sisse, et jälgida oma boonuseid ja allahindlusi.',
    log_in: 'Logi sisse',
    download_app_greet: 'Laadige alla meie rakendus ja saate boonuse',
    download: 'Laadi alla',
    welcome: 'Tere tulemast',
    studio_info: 'Stuudio teave',
    recommended_products: 'Soovitatavad tooted',
  }
};
