import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';
import { EStudentCandidateConfirmations } from '@/types/enums';

type TCreateStudentCandidateData = {
  who_studing: 'student' | 'guest'
  phone_number: string,
  first_name: string,
  last_name?: string,
  email?: string,
  created_by?: number,
  parlors: number,
  find_out?: number,
  types?: number[],
  social_link?: string,
  work_permit_document?: File
  sanitary_book?: File
  identity_proof?: File
  work_photos?: File[]
  start_date?: Date
  end_date?: Date,
  confirmations?: EStudentCandidateConfirmations[],
  couch?: number,
  can_show_documents: boolean
}
const createStudentCandidate = async (data: TCreateStudentCandidateData | FormData) => {
  const response = await axios.post(`${APIURL}/v1/student-candidate/`, data);
  return response.data;
};


type TCreateAgreementData = {
  student: number,
  answers: Array<{
    text: string,
    field: number | undefined,
    file_data: string | undefined,
  }>
}
const createAgreement = async (data: TCreateAgreementData) => {
  const response = await axios.post(`${APIURL}/v1/student-agreement-response/`, data);
  return response.data;
}

export {
  createStudentCandidate,
  createAgreement,
  TCreateStudentCandidateData,
};